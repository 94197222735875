
import * as moment from 'moment';

import { Box }  from './box';

export class TimeAxisBase extends Box {

  public toWidth(duration: number): number {
    return -1;
  }
  public toHeight(duration: number): number {
    return -1;
  }

  public toPos(date: moment): number {
    return -1;
  }

  public pan(offset: paper.Size) {
  }

}
