
import { Canvas }  from '../canvas';

export interface Clickable {

  click(canvas: Canvas);
  drawOver();
  drawOff();

}

export function instanceOfClickable(object: any): object is Clickable {
  return 'click' in object;
}
