
import { Point, Size, Rectangle, Path, Color } from 'paper';

import { Sequence }  from '../../sequence';
import { Box }  from '../box';
import { Canvas }  from '../canvas';
import { Editor }  from '../editor';
import { Text }  from '../text';
import { ExpandButton }  from '../button/expand-button';
import { PaletteCloseButton }  from '../button/palette-close-button';
import { Dragable }  from '../interface/dragable';
import { Targetable }  from '../interface/targetable';
import { Expandable }  from '../interface/expandable';
import { Closable }  from '../interface/closable';
import { Selectable }  from '../interface/selectable';

export class Palette extends Box implements Dragable, Expandable {

  title: string;
  private _size: paper.Size;
  private origHeight = 0;
  private expandable: boolean;
  private closable: boolean;
  private _expand: ExpandButton;

  constructor(title: string, expandable: boolean = true, closable: boolean = false) {

    super();

    this.title = title;
    this.expandable = expandable;
    this.closable = closable;

  }

  public typeName(): string {
    return "Palette";
  }

  public size(): paper.Size {
    return this._size;
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    this._size = size;
    this.removeChildren();
    this.addChildren(this.build());
    this.moveTo(topLeft);
  }

  public setSequence(sequence: Sequence) {
  }

  public setSelection(editor: Editor, selected: Selectable, multiple: boolean) {
    // override to do something on selection.
  }

  public isMainParent(): boolean {
    return true;
  }

  public bringAllToFront() {
    this.bringToFront();
  }
  public closeAll() {
  }
  public closePalette(title: string) {
  }
  public clickExpand(editor: Editor) {
    this._expand.click(editor);
  }

  public build(): paper.Item[] {

		var objs = [];

    objs.push(this.shadow());
    objs.push(this.paletteBorder());
    objs.push(this.insetRect());

    let size = this.size();

    let title = new Text(new Point(size.width/2, 18));
    title.fontSize = "12px";
    title.fontWeight = "bold";
    title.justification = "center";
    title.content = this.title;
    objs.push(title);

    if (this.closable) {
      let close = new PaletteCloseButton();
      close.rebuild(new Point(this.size().width - 20, 6), new Size(0, 0));
      objs.push(close);
    }

    if (this.expandable) {
      this._expand = new ExpandButton(true);
      this._expand.rebuild(new Point(this.size().width - (this.closable ? 40 : 20), 4), new Size(0, 0));
      objs.push(this._expand);
    }

    return objs;

  }

  public pan(offset: paper.Size) {
  }
  public endPan() {
  }
	public simpleChanged(state: boolean) {
	}

  // Dragable
	public draggingStarted(canvas: Canvas) {
	}
	public dragBy(canvas: Canvas, delta: paper.Size) {
	  this.position = this.position.add(new Point(delta));
	}
	public dragOver(dragable: Dragable) {
	}
	public draggedOver(targetable: Targetable): boolean {
	  return false;
	}
	public endDrag(canvas: Canvas, start: paper.Point, target: Targetable) {
	  console.log(this.title, this.bounds.x, this.bounds.y, (canvas as Editor)._size);
    (canvas as Editor).saveLocation(this.title, this.bounds.topLeft);
	}
	public shouldBringForward(): boolean {
		return true;
	}

  // Expandable
  public expand(canvas: Canvas) {
    this.scale(2.0, 2.0);
    this.translate(new Point(this.bounds.width / 4, this.bounds.height / 4));
    (canvas as Editor).saveExpanded(this.title, true);
  }

  public contract(canvas: Canvas) {
    this.scale(0.5, 0.5);
    this.translate(new Point(-this.bounds.width * 0.5, -this.bounds.height * 0.5));
    (canvas as Editor).saveExpanded(this.title, false);
  }

}
