import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BackendService } from './backend.service';
import { Stream } from './stream';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';

@Injectable()
export class StreamService extends BackendService {

  private streamsUrl = '/rest/1.0/streams';

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
  ) {
    super(dialog, socketService, upService, http)
  }

  getStreams(limit: number, latest: boolean): Observable<Stream[]> {
    var url = `${this.streamsUrl}/?offset=0&limit=${limit}&app=timeline`;
    if (latest) {
      url += "&latest=true"
    }
    return this.get<Stream[]>(url, "getStreams");

  }

  searchStreamIdeas(term: string, start: string, end: string, allstreams: boolean, map: boolean): Observable<any> {
    var encstart = encodeURIComponent(start);
    var encend = encodeURIComponent(end);
    var url = `${this.streamsUrl}/ideas?q=${term}&start=${encstart}&end=${encend}`;
    if (allstreams) {
      url += "&allstreams=true"
    }
    if (map) {
      url += "&views.map.lat_long=true"
    }
    return this.get<any>(url, "searchStreamIdeas");

  }

  getStreamNoSecurityError(id: string): Observable<Stream> {

    var url = `${this.streamsUrl}/${id}`;
    return this.http.get<Stream>(url).pipe(
      catchError(this.passError<Stream>(`getStreamNoSecurityError id=${id}`))
    );

  }

  getStream(id: string): Observable<Stream> {
    const url = `${this.streamsUrl}/${id}`;
    return this.http.get<Stream>(url).pipe(
      catchError(this.handleError<Stream>(`getStream id=${id}`))
    );
  }

  searchStreams(term: string, limit: number): Observable<Stream[]> {
    if (!term.trim()) {
      // if not search term, return empty stream array.
      return of([]);
    }
    return this.http.get<Stream[]>(`${this.streamsUrl}/?q=${term}&offset=0&limit=${limit}`).pipe(
      catchError(this.handleError<Stream[]>('searchStreams', []))
    );
  }

}
