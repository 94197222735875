
import { Path, Point, Rectangle, Size, Color, PointText, Group, Item } from 'paper';

import { Box }  from '../box';
import { Clickable }  from '../interface/clickable';
import { Closable, instanceOfClosable }  from '../interface/closable';
import { Canvas }  from '../canvas';

export class PaletteCloseButton extends Box implements Clickable {

  constructor() {

    super();

  }

  public typeName(): string {
    return "PaletteCloseButton";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
  }

  public size(): paper.Size {
    return new Size(14, 14);
  }

  private _build(): paper.Item[] {

 		var objs = [];
    objs.push(this.empty());
//    objs.push(this.border());

    let text = new PointText(new Point(4, 8));
    text.fillColor = new Color('black');
    text.content = "x";
    text.fontSize = "14px";
    objs.push(text);

    return objs;

  }

  // Clickable
  public drawOver() {
    (this.children[1] as paper.PointText).fontWeight = "bold";
  }
  public drawOff() {
    (this.children[1] as paper.PointText).fontWeight = "normal";
  }
	public click(canvas: Canvas) {
	  let parent = this.parent as Box;
	  if (instanceOfClosable(parent)) {
	    ((parent as any) as Closable).close(canvas);
	  }
	}

}
