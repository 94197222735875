
import { PointText, Point, Color } from 'paper';

export class Text extends PointText {

  constructor(point: paper.Point) {

    super(point);
    this.justification = 'left';
    this.fillColor = new Color('black');
    this.fontFamily = "Roboto,Helvetica Neue,sans-serif";

  }

  public clipChars(s: string, maxWidth: number) {

    var line = "";
    var lastline = "";
    for (var i=0; i<s.length; i++) {
      lastline = line;
      line += s.substr(i, 1);
      this.content = line;
      if ((this.bounds.width + 3) >= maxWidth) {
        this.content = lastline + "...";
        return;
      }
    }
    this.content = s;
  }

  public clipText(s: string, maxWidth: number) {

    if (!s || s.length == 0) {
      this.content = "";
      return;
    }

    let words = s.split(" ");
    if (words.length == 1) {
      this.content = s;
      if (this.bounds.width >= maxWidth) {
        this.clipChars(this.content, maxWidth);
      }
      return;
    }

//    var lastline = "";
    var line = "";
    for (var i=0; i<words.length; i++) {
      this.content = line;
      var width = this.bounds.width;
      if (width >= maxWidth) {
        this.clipChars(line, maxWidth);
        return;
      }
//      lastline = line;
      line += words[i] + " ";
    }
    this.clipChars(line, maxWidth);

  }

  public wrapChars(s: string, maxWidth: number): string {

    var line = "";
    var lastline = "";
    var newText = "";
    for (var i=0; i<s.length; i++) {
      lastline = line;
      var c = s.substr(i, 1);
      line += c;
      this.content = line;
      if ((this.bounds.width + 1) >= maxWidth) {
        newText = lastline + "\n";
        line = c;
      }
    }
    return newText + line;
  }

  public wrapText(s: string, maxWidth: number) {

    if (!s || s.length == 0) {
      this.content = "";
      return;
    }

    let words = s.split(" ");
    if (words.length == 1) {
      this.content = this.wrapChars(s, maxWidth);
      return;
    }

    var lastline = "";
    var line = "";
    var newText = "";
    words.forEach(w => {
      lastline = line;
      if (line.length > 0) {
        line += " ";
      }
      line += w;
      this.content = line;
      var width = this.bounds.width;
      if (width >= maxWidth) {
        newText += this.wrapChars(lastline, maxWidth) + "\n";
        line = w;
      }
    });

    this.content = newText + this.wrapChars(line, maxWidth);
  }

}
