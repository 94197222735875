
import { Canvas }  from '../canvas';

export interface Selectable {

  canSelect(): boolean;
  select(canvas: Canvas);
  deselect(canvas: Canvas);

}

export function instanceOfSelectable(object: any): object is Selectable {
  return 'select' in object;
}
