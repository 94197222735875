
import { Point, Size } from 'paper';

import { IconButton }  from './icon-button';
import { Canvas }  from '../canvas';
import { Editor }  from '../editor';
import { Icons }  from '../icons';

export class ZoomOutButton extends IconButton {

  constructor() {

    super(24);

  }

  public typeName(): string {
    return "ZoomOutButton";
  }

  public setContent() {
    this.setIcon(Icons.ZOOM_OUT);
  }

	public click(canvas: Canvas) {
	  (canvas as Editor).zoomOut();
  }

}
