
import { Raster, Point } from 'paper';

import { Box }  from './box';

export class Icon extends Raster {

  load(size: number, loaded: () => void) {
    this.onLoad = () => {
      this.scale(size / this.width);
      loaded();
    };
  }

  moveTo(point: paper.Point) {
    this.position = point;
  }

}
