import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

import { TextChange } from './text-change';

@Injectable({
  providedIn: 'root'
})
export class TextChangedService {

  private textChangedSource = new Subject<TextChange>();
  textChanged$ = this.textChangedSource.asObservable();

  constructor() { }

  changed(field: string, text: string) {
    var change = new TextChange();
    change.field = field;
    change.text = text;
    this.textChangedSource.next(change);
  }

}
