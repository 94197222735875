import { Injectable, EventEmitter, } from '@angular/core';
import * as io from 'socket.io-client';

import { User }  from './user';
import { SockDoc }  from './sockdoc';
import { UpService }  from './up.service';

@Injectable()
export class SocketService {

  // Our socket connection
  private socket;

  private dataHandlers  = [];
  private msgHandlers = [];
  private updateHandlers = [];
  private readHandlers = [];
  private typingHandlers = [];
  private clickingHandlers = [];
  private mediaHandlers = [];

  private me: User;
  private streams: SockDoc[] = [];
  private users: SockDoc[] = [];

  constructor(
    private upService: UpService
  ) {}

  setExtID(id: string) {
    this.socket = { id: id };
  }

  start(me: User, logger: any, observer: EventEmitter<string>) {

    if (!logger) {
      logger = { log: (s) => {} }
    }

    this.me = me;

    if (!this.socket) {
      this.socket = io();
    }

    this.socket.on('id', () => {
      this.upService.connected();
      observer.next("socket ready");
    });
    this.socket.on('system', (data) => {
//      logger.log("socket", "system", data);
      if (data.type == "restart-maintenance") {
        this.upService.disconnected();
      }
    });
    this.socket.on('media', (data) => {
//      logger.log("socket", "media", data);
      this.mediaHandlers.forEach(e => {
        e.emit(data);
      });
    });
    this.socket.on('data', (data) => {
//      logger.log("socket", "data", data);
      observer.next("data " + this.dataHandlers.length);
      this.dataHandlers.forEach(e => {
        e.emit(data);
      });
    });
    this.socket.on('message', (data) => {
//      logger.log("socket", "message", data);
//      observer.next("message " + this.msgHandlers.length);
      this.msgHandlers.forEach(e => {
        e.emit(data);
      });
    });
    this.socket.on('update', (data) => {
//      logger.log("socket", "update", data);
//      observer.next("update " + this.updateHandlers.length);
      this.updateHandlers.forEach(e => {
        e.emit(data);
      });
    });
    this.socket.on('read', (data) => {
//      logger.log("socket", "read", data);
//      observer.next("read " + this.readHandlers.length);
      this.readHandlers.forEach(e => {
        e.emit(data);
      });
    });
    this.socket.on('typing', (data) => {
//      logger.log("socket", "typing", data);
//      observer.next("typing " + this.typingHandlers.length);
      this.typingHandlers.forEach(e => {
        e.emit(data);
      });
    });
    this.socket.on('clicking', (data) => {
//      logger.log("socket", "clicking", data);
//      observer.next("clicking " + this.clickingHandlers.length);
      this.clickingHandlers.forEach(e => {
        e.emit(data);
      });
    });

    this.socket.on('connect_error', (err) => {
//      logger.log("e." + err.type, err);
        this.upService.down();
    });
    this.socket.on('connect_timeout', () => {
//      logger.log("t");
    });
    this.socket.on('reconnect', (n) => {
//      logger.log("r." + n);
      this.upService.reconnected();
    });
    this.socket.on('reconnect_attempt', () => {
      setTimeout(() => {
        this.start(me, logger, observer);
      }, 500);
    });
    this.socket.on('reconnecting', () => {
      this.upService.reconnecting();
//      logger.log("ring");
    });
    this.socket.on('reconnect_error', (err) => {
//      logger.log("re." + err.type, err);
    });
    this.socket.on('reconnect_failed', () => {
//      logger.log("rf");
    });
  }

  startDown(logger: any, observer: EventEmitter<string>) {

    if (!logger) {
      logger = { log: (s) => {} }
    }

    this.upService.disconnected();

    if (!this.socket) {
      this.socket = io();
    }

    this.socket.on('reconnect', (n) => {
//      logger.log("r." + n);
      this.upService.reconnected();
    });
    this.socket.on('reconnect_attempt', () => {
      setTimeout(() => {
        this.startDown(logger, observer);
      }, 500);
    });
    this.socket.on('reconnecting', () => {
      this.upService.reconnecting();
//      logger.log("ring");
    });

  }

  setStreams(streams: string[]) {
    if (streams) {
      this.streams = streams.map(e => { return { id: e, type: "stream" } as SockDoc });
    }
    else {
      this.streams =  [];
    }
  }

  setUsers(users: string[]) {
    this.users = users.map(e => { return { id: e, type: "user" } as SockDoc });
  }

  openDocs() {
    this.closeDocs();
    let docs = this.streams.concat(this.users);
    this.socket.emit('openDocuments', { docs:docs, userid: this.me._id });
  }

  closeDocs(): void {
    this.socket.emit('closeDocuments', {});
  }

  registerMsg(emitter: EventEmitter<any>) {
    this.msgHandlers.push(emitter);
  }

  registerRead(emitter: EventEmitter<any>) {
    this.readHandlers.push(emitter);
  }

  registerData(emitter: EventEmitter<any>) {
    this.dataHandlers.push(emitter);
  }

  registerUpdate(emitter: EventEmitter<any>) {
    this.updateHandlers.push(emitter);
  }

  registerTyping(emitter: EventEmitter<any>) {
    this.typingHandlers.push(emitter);
  }

  registerClicking(emitter: EventEmitter<any>) {
    this.clickingHandlers.push(emitter);
  }

  registerMedia(emitter: EventEmitter<any>) {
    this.mediaHandlers.push(emitter);
  }

  id(): string {
    return this.socket ? this.socket.id : null;
  }

}
