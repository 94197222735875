
import { Size, Point, Item, Rectangle, Path, Color } from 'paper';

import * as moment from 'moment';

import { TimeAxisBase }  from './time-axis-base';
import { Text }  from './text';
import { TimeRef }  from './time-ref';

export class TimeAxis extends TimeAxisBase {

  private ticks: paper.Item[] = [];
  private tref: TimeRef;
  private _size: paper.Size;
  private now: paper.Path.Rectangle;
  private format: any;
  private lastTick = -1;
  private start: moment;

  static TOP: number = 30;

  private tickData = [
    { unit: "second", limit: 30, format: { unit: "second", inc: 1, format: "HH:mm:ss", mainUnit: "minute", mainFormat: "D MMM YYYY" } },
    { unit: "second", limit: 60, format: { unit: "second", inc: 1, format: ":ss", mainUnit: "minute", mainFormat: "D MMM YYYY HH:mm" } },
    { unit: "minute", limit: 60, format: { unit: "minute", inc: 1, format: ":mm", mainUnit: "hour", mainFormat: "D MMM YYYY HH:mm" } },
    { unit: "hour", limit: 24, format: { unit: "hour", inc: 1, format: "HH:mm", mainUnit: "day", mainFormat: "D MMM YYYY" } },
    { unit: "day", limit: 2, format: { unit: "hour", inc: 1, format: "HH:mm", mainUnit: "day", mainFormat: "D MMM YYYY" } },
    { unit: "day", limit: 32, format: { unit: "day", inc: 1, format: "ddd D", mainUnit: "month", mainFormat: "MMM YYYY" } },
    { unit: "month", limit: 2, format: { unit: "day", inc: 1, format: "D", mainUnit: "month", mainFormat: "MMM YYYY" } },
    { unit: "month", limit: 12, format: { unit: "month", inc: 1, format: "MMM", mainUnit: "year", mainFormat: "YYYY" } },
    { unit: "year", limit: 10, format: { unit: "month", inc: 1, format: "MMM", mainUnit: "year", mainFormat: "YYYY" } },
    { unit: "year", limit: 25, format: { unit: "year", inc: 1, mainUnit: "year", mainFormat: "YYYY" } },
    { unit: "year", limit: 100, format: { unit: "year", inc: 10, mainUnit: "year", mainFormat: "YYYY" } }
  ];

  constructor(tref: TimeRef) {

    super();

    this.tref = tref;

  }

  public typeName(): string {
    return "TimeAxis";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {

    this._size = size;
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
    this.clipped = true;

  }

  public size(): paper.Size {
    return this._size;
  }

  public animate(time: number) {
    if (!this.now) {
      return;
    }
    if (this.lastTick < 0) {
      this.start = moment();
      this.lastTick = 0;
    }
    else {
      let tick = Math.round(time);
      if (tick > this.lastTick) {
        let now = this.start.clone();
        now.add(time - 1, "seconds");
        let pos = this.toPos(now);
//        console.log(pos);
        this.now.position.x = pos;
        this.lastTick = tick;
      }
    }
  }

  public rangeFromPos(x: number): moment {
    let range = this.tref.end.diff(this.tref.start);
    return moment.duration(range * (x / this.size().width));
  }

  public toPos(date: moment): number {
    let diff = date.diff(this.tref.start);
    let range = this.tref.end.diff(this.tref.start);
    return (diff * this.size().width) / range;
  }

  public toWidth(duration: number): number {
    let range = this.tref.end.diff(this.tref.start);
    return ((duration * 1000) * this.size().width) / range;
  }

  public pan(offset: paper.Size) {

    let diff = this.rangeFromPos(offset.width);
    this.tref.pan(diff);

    this.ticks.forEach(t => {
      t.position.x += offset.width;
    });
    if (this.now) {
      this.now.position.x += offset.width;
    }

  }

  private _build(): paper.Item[] {

 		var objs = [];
 		objs.push(this.blank());

    let size = this.size();

    {
      let nowx = this.toPos(moment());
      if (nowx >= 20 && nowx < (size.width - 20)) {
        this.now = new Path.Rectangle(new Point(nowx - 10, 0), new Size(20, size.height));
        this.now.fillColor = new Color({
          gradient: {
              stops: [['white', 0], ['skyBlue', 0.45], ['deepSkyBlue', 0.5], ['skyBlue', .55], ['white', 1]]
          },
          origin: [nowx - 10, size.height/2],
          destination: [nowx + 10, size.height/2]
        });
        objs.push(this.now);
      }
   }

    this.format = this.calcTickFormat();
    if (this.format) {
      this.ticks = this.buildAllTicks();
      this.ticks.forEach(t => objs.push(t));
    }

//     {
//       let text = new Text(new Point(10, size.height-16));
//       text.content = this.format.unit;
//       objs.push(text);
//     }

    return objs;

  }

  private filterFormat(unit: string, limit: number) {
    var ticks = this.tickData.filter(e => e.unit == unit && limit < e.limit);
    if (ticks.length > 0) {
      return ticks[0].format;
    }
    return null;
  }

  private calcTickFormat() {

    var logger = { log: (...args: any[]) => {} };
//    var logger = console;

    let duration = moment.duration(this.tref.end.diff(this.tref.start));
    let seconds = duration.asSeconds();
    logger.log("seconds", seconds);
    var format = this.filterFormat("second", seconds);
    if (format) {
      return format;
    }
    let minutes = duration.asMinutes();
    logger.log("minutes", minutes);
    format = this.filterFormat("minute", minutes);
    if (format) {
      return format;
    }
    let hours = duration.asHours();
    logger.log("hours", hours);
    format = this.filterFormat("hour", hours);
    if (format) {
      return format;
    }
    let days = duration.asDays();
    logger.log("days", days);
    format = this.filterFormat("day", days);
    if (format) {
      return format;
    }
    let months = duration.asMonths();
    logger.log("months", months);
    format = this.filterFormat("month", months);
    if (format) {
      return format;
    }
    let years = duration.asYears();
    logger.log("years", years);
    format = this.filterFormat("year", years);
    if (format) {
      return format;
    }

    return null;

  }

  private buildAllTicks(): any[] {

    var logger = { log: (...args: any[]) => {} };
//    var logger = console;
    logger.log("buildAllTicks", this.format);

    var anyMain = false;
    let date = this.tref.start.clone().startOf(this.format.unit);
    var ticks = [];
    let height = this.size().height;
    let width = this.size().width;
    for (;;) {

      if (date.isSameOrAfter(this.tref.end)) {
        logger.log("end", date.format());
        if (!anyMain) {
          let text = new Text(new Point(8, 14));
          text.content = this.tref.start.format(this.format.mainFormat);
          text.justification = "left";
          ticks.push(text);
        }
        return ticks;
      }

      if (this.tref.start.isSameOrAfter(date)) {
        logger.log("skip", date.format());
        date.add(this.format.inc, this.format.unit + 's');
        continue;
      }

      let tickx = this.toPos(date);
      if (tickx >= 0 && tickx <= width) {
        logger.log("draw at", tickx);

        let mainTick = date.clone().startOf(this.format.mainUnit).isSame(date);

        let tick = new paper.Path();
        tick.strokeColor = new Color(mainTick ? 'grey' : 'gainsboro');
        tick.moveTo(new paper.Point(tickx, 0));
        tick.lineTo(new paper.Point(tickx, height));
        ticks.push(tick);

        let tickleft = tickx + 4;
        if (mainTick) {
          if ((tickleft + 80) < width) {
            anyMain = true;
            let text = new Text(new Point(tickleft, 12));
            text.content = date.format(this.format.mainFormat);
            logger.log("mainFormat", text.content);
            ticks.push(text);
          }
        }
        if (this.format.format && this.format.format.length > 0) {
          if ((tickleft + 20) < width) {
            let text = new Text(new Point(tickleft, 26));
            text.content = date.format(this.format.format);
            text.fontSize = "10px";
            text.fillColor = new Color('grey');
            logger.log("format", text.content);
            ticks.push(text);
          }
        }
      }

      date.add(this.format.inc, this.format.unit + 's');

    }

    return [];
  }

}
