import { Component, EventEmitter  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { MeService }  from './me.service';
import { Site }  from './site';
import { SiteService }  from './site.service';
import { Me }  from './me';
import { IconService }  from './icon.service';
import { UIBuildService }  from './uibuild.service';
import { SocketService }  from './socket.service';
import { ZZZVersion } from './zzzversion';
import { SearchQueryService }  from './search-query.service';
import { UIFlags } from '../../../shared-ui/uiflags';
import { SearchQuery }  from './search-query';
import { Editor }  from './objects/editor';
import { NewStreamService }  from './new-stream.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'timeline-ui';
  site: Site;
  me: Me;
  height = "300px";
  needsNewBuild = false;
  flags: UIFlags;
  advanced = false;
  range = 30;
  date: Date;
  newstreams = 0;
  embed = false;

  private sockets = true;

  constructor(
    private route: ActivatedRoute,
    private meService: MeService,
    private siteService: SiteService,
    private iconService: IconService,
    private uibuildService: UIBuildService,
    private socketService: SocketService,
    private searchQueryService: SearchQueryService,
    private newStreamService: NewStreamService,
  ) {
    this.date = new Date();
    route.queryParams.subscribe(params => {
      this.sockets = params['sockets'] != "false";
      let extid = params['extsocket'];
      if (extid) {
        this.socketService.setExtID(extid);
      }
      this.embed = params['embed'] == "true";
    });
    this.newStreamService.newStream$.subscribe(stream => {
      this.newstreams++;
    });
  }

  ngOnInit() {
    let url = new URL(window.location.href);
    this.siteService.getSite(url.hostname).subscribe(site => {
      this.site = site;
      this.setTitle();
    });
    this.uibuildService.getUIBuild("timeline").subscribe(uibuild => {
      this.needsNewBuild = uibuild != ZZZVersion.uibuild;
    });
    this.meService.getMe().subscribe(me => {
      this.me = me;
      this.flags = new UIFlags(this.me.uibits);
    });
  }

  private setTitle(): void {
    document.title = this.headerTitle() + " Timeline";
  }

  tabTitle(): string {
    return this.site && this.site.tabTitle ? this.site.tabTitle : "Timeline";
  }

  headerTitle(): string {
    return this.site && this.site.headerTitle ? this.site.headerTitle : "Visual Ops";
  }

  imageSrc(): string {
    return this.site ? (this.site.image ? "/rest/1.0/media/" + this.site.image + "/thumbnail" : "assets/visualops-icon.svg") : "";
  }

  convoLinkName(): string {
    return (this.me && this.me.name == 'public' && this.site && this.site.convoLinkName) ? this.site.convoLinkName : "Conversations";
  }

  getIcon(me: Me): string {
    return this.iconService.getIcon(me);
  }

  onResize(event: any) {
    this.height = window.innerHeight + "px";
  }

  gotoApp(name: string, newwindow = false) {
    var link = "../" + name;
    if (name == "conversations" && this.site && this.site.convoLinkStream) {
      link += "/#/streams/" + this.site.convoLinkStream;
    }
    window.open(link, newwindow && !this.inIOS() ? "_blank" : "_self");
  }

  inIOS(): boolean {
    let webkit = (window as any).webkit;
    return webkit && webkit.messageHandlers.vops;
  }

  version(): string {
    return ZZZVersion.uibuild;
  }

  hasAdmin(): boolean {
    return this.me && this.me.admin;
  }

  logoff() {
    window.open("/logout", "_self");
  }

  openSearch() {
    this.searchQueryService.open();
  }

  search(query: string) {
    if (!this.advanced) {
      this.searchQueryService.changed(query);
    }
  }

  closeSearch() {
    this.searchQueryService.close();
  }

  chooseDate(event) {
    this.date = event.value;
  }

  rangeText() {
    return this.formatMins(this.range);
  }

  formatMins(value: number) {
    if (value < 60) {
      return value + " m";
    }
    else if (value < 4320){
      return Math.round(value / 60) + " h";
    }
    else {
      return Math.round(value / 1440) + " d";
    }
  }

  advancedSearch(query: string) {
    var q = new SearchQuery();
    q.text = query;
    q.useIdeas = true;
    q.date = moment(this.date).format();
    q.range = this.range;
    this.searchQueryService.advanced(q);
  }

  switchSearchMode() {
    this.advanced = !this.advanced;
    if (!this.advanced) {
      this.searchQueryService.close();
    }
  }

  newStreams(): string {
    if (this.newstreams > Editor.MAX_STREAMS_LATEST) {
      return Editor.MAX_STREAMS_LATEST + "+";
    }
    return this.newstreams.toString();
  }

  showFavorites(): boolean {
    return this.site && this.site.timelineShowSearchBar && !this.site.timelineShowAllStreams && this.me && this.me.name != 'public';
  }
}
