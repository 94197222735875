import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowIdeaService {

  private showIdeaSource = new Subject<string>();
  showIdeaChanged$ = this.showIdeaSource.asObservable();

  constructor() { }

  show(idea: string) {
    this.showIdeaSource.next(idea);
  }
}
