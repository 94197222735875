
import { Size, Point, Item, PointText, Color } from 'paper';

import { Box }  from './box';
import { HelpBubble }  from './help-bubble';

export class HelpLayer {

  private bubbleSpecs = [];
  private bubbles: HelpBubble[] = [];

  addSpec(context: string, text: string, pos: paper.Point) {

    if (this.bubbleSpecs.find(e => e.context == context)) {
      console.log(context, "already added");
      return;
    }
    this.bubbleSpecs.push({ context: context, text: text, pos: pos });

  }

  helpShowing(): boolean {
    return this.bubbles.length > 0;
  }

  showHelp() {
    this.hideHelp();
    this.bubbleSpecs.forEach(s => {
      let bubble = new HelpBubble(s.text);
      bubble.rebuild(s.pos, new Size(0, 0));
      this.bubbles.push(bubble);
    });
  }

  hideHelp() {
    if (this.bubbles) {
      this.bubbles.forEach(b => {
        b.remove();
        b.release();
      });
      this.bubbles = [];
    }
  }

  bringToFront() {
    this.bubbles.forEach(b => {
      b.bringToFront();
    });
  }
}
