
import { Point, Size, Rectangle, Path, Color } from 'paper';

import { Site }  from '../../site';
import { ClosablePalette }  from './closable-palette';
import { Text }  from '../text';
import { Button }  from '../button/button';
import { ZoomInButton }  from '../button/zoom-in-button';
import { ZoomOutButton }  from '../button/zoom-out-button';
import { ZoomToButton }  from '../button/zoom-to-button';
//import { StreamsButton }  from '../button/streams-button';
import { ResetButton }  from '../button/reset-button';
import { HelpButton }  from '../button/help-button';
import { RecentButton }  from '../button/recent-button';
import { NowButton }  from '../button/now-button';
import { RotateButton }  from '../button/rotate-button';
import { Editor }  from '../editor';

import { Selectable }  from '../interface/selectable';

export class ToolPalette extends ClosablePalette {

  static BUTTON_WIDTH: number = 70;
  static BUTTON_HEIGHT: number = Button.HEIGHT + 8;
  static WIDTH: number = ToolPalette.BUTTON_WIDTH + 30;
  static HEIGHT: number = (10 * ToolPalette.BUTTON_HEIGHT) + 34;

  constructor(
    private site: Site
  ) {

    super("Zoom", false);

  }

  public typeName(): string {
    return "ToolPalette";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    super.rebuild(topLeft, new Size(ToolPalette.WIDTH, ToolPalette.HEIGHT));
  }

  public setSelection(editor: Editor, selected: Selectable) {
  }

  public build(): paper.Item[] {

		var objs = super.build();

    let size = this.size();

    var loc = new Point(size.width/2 - (ToolPalette.BUTTON_WIDTH/2), ToolPalette.BUTTON_HEIGHT);
    {
      let b = new ZoomInButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new ZoomOutButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new NowButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new RecentButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new ZoomToButton("Today", "day");
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new ZoomToButton("This Week", "week");
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new ZoomToButton("This Month", "month");
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
/*    {
      let b = new ZoomToButton("This Year", "year");
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }

    {
      let b = new StreamsButton(this.site.tabTitle ? this.site.tabTitle : "Streams");
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
*/
    {
      let b = new ResetButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new HelpButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }
    {
      let b = new RotateButton();
      b.rebuild(loc, new Size(ToolPalette.BUTTON_WIDTH, 0));
      objs.push(b);
      loc.y += ToolPalette.BUTTON_HEIGHT;
    }

    return objs;

  }

}
