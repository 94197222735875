
import { Palette }  from './palette';
import { Canvas }  from '../canvas';
import { Editor }  from '../editor';

import { Closable }  from '../interface/closable';

export class ClosablePalette extends Palette implements Closable {

  constructor(title: string, expandable: boolean = true, closable: boolean = true) {

    super(title, expandable, closable);

  }

  // closable
  public close(canvas: Canvas) {
    (canvas as Editor).closePalette(this.title);
  }

}
