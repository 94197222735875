
export class SearchQuery {

  opened: boolean;
  latest: boolean;
  now: boolean;
  text: string;
  useIdeas: boolean;
  date: string;
  range: number;

}
