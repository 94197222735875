
import { Path, Point, Rectangle, Size, Color, PointText, Group, Item } from 'paper';

import { Box }  from '../box';
import { Text }  from '../text';
import { Canvas }  from '../canvas';

import { Clickable }  from '../interface/clickable';

export class IconButton extends Box implements Clickable {

  private width: number;
  private pos: paper.Point;
  private bgColor: string;
  private color: string;

  constructor(width: number, bgColor = "white", color = "black") {

    super();
    this.width = width;
    this.bgColor = bgColor;
    this.color = color;
//    console.log("".charCodeAt(0));
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    var top: number;
    if (this.width <= 16) {
	    top = 16;
    }
    else if (this.width <= 24) {
	    top = 22;
    }
    else {
	    top = 32;
    }
    this.pos = new Point(size.width > 0 ? (size.width - this.width) / 2 : 0, top);
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
    this.setContent();
  }

  public size(): paper.Size {
    return new Size(this.width, this.width);
  }

  private _build(): paper.Item[] {

 		var objs = [];

    let size = this.size();
    {
      let rect = new Rectangle(new Point(0, 0), size);
      let r = new Path.Rectangle(rect, new Size(2, 2));
      r.closed = true;
      r.fillColor = new Color(this.bgColor);
      objs.push(r);
    }

    let p = new Text(this.pos);
    p.justification = "left";
    p.fontFamily = "Material Icons";
    if (this.width <= 16) {
	    p.fontSize = "16px";
    }
    else if (this.width <= 24) {
	    p.fontSize = "24px";
    }
    else {
	    p.fontSize = "32px";
    }
    p.fillColor = new Color(this.color);
    objs.push(p);

    return objs;

  }

  public setContent() {
  }

  public setIcon(icon: number) {
    let text = this.children[1] as Text;
	  text.content = String.fromCharCode(icon);
  }

  // Clickable
  public drawOver() {
  }
  public drawOff() {
  }
	public click(canvas: Canvas) {
	}

}
