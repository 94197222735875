
import { Size, Point, Item, Rectangle, Path, Color } from 'paper';

import { Idea }  from '../idea';
import { Stream }  from '../stream';

import { Canvas }  from './canvas';
import { Editor }  from './editor';
import { Box }  from './box';
import { Text }  from './text';
import { IdeaObject }  from './idea-object';
import { ObjectColors }  from './object-colors';
import { HelpLayer }  from './help-layer';

import { Overable }  from './interface/overable';
import { DblClickable }  from './interface/dblclickable';

export class TinyIdeaObject extends Box implements Overable, DblClickable {

  private _size: paper.Size;
  private editor: Editor;
  private _border: paper.Path.Rectangle;

  stream: Stream;
  idea: Idea;

  constructor(editor: Editor, stream: Stream, idea: Idea,
  ) {

    super();
    this.editor = editor;
    this.stream = stream;
    this.idea = idea;

  }

  public typeName(): string {
    return "TinyIdeaObject";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {

    this._size = size;
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);

  }

  public size(): paper.Size {
    return this._size;
  }

  public pan(offset: paper.Size) {
    this.position.x += offset.width;
    this.position.y += offset.height;
  }

  public doLayout() {
    this.layout();
    if (this.parent instanceof Box) {
      (this.parent as Box).doLayout();
    }
  }

  public layout() {
    this._border.remove();
    this._border = this.ideaBorder(new Point(this.bounds.x, this.bounds.y + 2));
    this.addChild(this._border);
    this._border.sendToBack();
  }

  public addHelp(help: HelpLayer) {

    help.addSpec("rollover-tiny", "Rollover any of the rounded boxes to see the contents.", new Point(this.bounds.left + 20, this.bounds.top + 60));

  }

  private ideaBorder(loc: paper.Point): paper.Path.Rectangle {

    let size = this.size();

    var hasDuration = this.idea.views && this.idea.views.timeline && this.idea.views.timeline.duration;
    var width = IdeaObject.TINY_WIDTH;
    if (hasDuration) {
      width = this.editor.timeAxis.toWidth(this.idea.views.timeline.duration);
    }
    if (width < 8) {
      width = 8;
    }
    let rect = new Rectangle(loc, new Size(width, IdeaObject.TINY_HEIGHT-2));
    let r = new Path.Rectangle(rect, new Size(hasDuration ? 0: 3, hasDuration ? 0: 3));
    r.closed = true;
    r.fillColor = this.idea.views && this.idea.views.all && this.idea.views.all.bgColor ? ObjectColors.parseColor(this.idea.views.all.bgColor, false) : new Color("white");
    r.strokeColor = this.idea.views && this.idea.views.all && this.idea.views.all.color ? ObjectColors.parseColor(this.idea.views.all.color, false) : new Color("black");
    r.strokeWidth = 1;
    return r;

  }

  private _build(): paper.Item[] {

 		var objs = [];
    objs.push(this.blank());
//    objs.push(this.debug());

    this._border = this.ideaBorder(new Point(0, 2));
    objs.push(this._border);

    return objs;

  }

	// Overable
	over(canvas: Canvas, position: paper.Point) {
	  this.editor.popupIdea(this.stream, this.idea, this.bounds, position);
	}

  // DblClickable
	public dblClick(canvas: Canvas) {
    this.editor.showIdea(this.idea._id);
  }

}
