
export enum Icons {
  WARNING_SOLID = 57344,  // Glyph 40
  // 57345...57395 (50)
  PAUSE = 57396,          // Glyph 64
  // 57397...57519 (122)
  CALL = 57520,           // Glyph 125
  // 57521...57668 (147)
  PLUS = 57669,           // Glyph 174
  // 57670...57748 (78)
  PLANE = 57749,          // Glyph 220
  // 57750...58043 (293)
  PAPER_CLIP = 58044,     // Glyph 330
  // 58045...58347 (302)
  GRID = 58348,           // Glyph 470
  // 58349...58390 (41)
  SEE = 58391,            // Glyph 510
  // 58392...58419 (27)
  EXPAND_SCREEN = 58420,  // Glyph 538
  // 58421...58730 (309)
  FULLSCREEN = 58731,     // Glyph 607
  // 58420...58829 (409)
  CONTRACT = 58830,       // Glyph 626
  EXPAND = 58831,         // Glyph 627
  // 58832...58923 (91)
  CAR = 58924,            // Glyph 670
  // 58925...59516 (684)
  FAVOURITE = 59517,      // Glyph 784
  UN_FAVOURITE = 59518,   // Glyph 785
  // 59519...59540 (21)
  LINK = 59541,           // Glyph 808
  LEGEND = 59542,         // Glyph 809
  // 59543...59575 (32)
  TOOLBAR = 59576,        // Glyph 842
  // 59576...59646 (70)
  ZOOM_IN = 59647,        // Glyph 912
  ZOOM_OUT = 59648,       // Glyph 913
  // 59649...60235 (586)
  LOTUS = 60236,          // Glyph 971
}
