
export interface Linkable {

  linkTo();

}

export function instanceOfLinkable(object: any): object is Linkable {
  return 'linkTo' in object;
}
