
import { Point, Size } from 'paper';

import { Button }  from './button';
import { Editor }  from '../editor';

export class ZoomToButton extends Button {

  private title: string;
  private _scale: string;

  constructor(title: string, scale: string) {

    super();
    this.title = title;
    this._scale = scale;

  }

  public typeName(): string {
    return "ZoomToButton";
  }

  public testName(): string {
    return this.title;
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    super.rebuild(topLeft, size);
    this.setContent(this.title);
  }

  public run(editor: Editor) {
    editor.zoomTo(this._scale, true);
  }

}
