import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BackendService } from './backend.service';
import { Me }  from './me';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';

@Injectable()
export class MeService extends BackendService {

  private meUrl = '/rest/1.0/users/me';
  private me: Me;

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
  ) {
    super(dialog, socketService, upService, http)
  }

  getMe(): Observable<Me> {

    if (this.me) {
      return of(this.me);
    }

    return this.get<Me>(this.meUrl, "getMe").pipe(
       tap(me => this.me = me)
    );

  }

  getSettings(app: string): Observable<any> {

    let url = `${this.meUrl}/settings/${app}`
    return this.get<any>(url, "getSettings");

  }

  updateSettings(app: string, settings: any): Observable<any> {

    let url = `${this.meUrl}/settings/${app}`
    return this.http.patch<any>(url, settings, { headers: this.httpHeaders() }).pipe(
      catchError(this.handleError<any>('updateSettings'))
    );

  }

  deleteSettings(app: string): Observable<any> {

    let url = `${this.meUrl}/settings/${app}`
    return this.http.delete<any>(url, { headers: this.httpHeaders() }).pipe(
      catchError(this.handleError<any>('deleteSettings'))
    );

  }

  dateRange(start: string, end: string): Observable<any> {
    return this.post<any>(`${this.meUrl}/daterange`, { start: start, end: end }, "daterange");
  }

  showIdea(idea: string): Observable<any> {
    return this.post<any>(`${this.meUrl}/showidea`, { idea: idea }, "idea");
  }

}
