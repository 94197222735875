
import { Size, Point, Item, Rectangle, Path, Color, Group, Raster } from 'paper';

import * as moment from 'moment';

import { Me }  from '../me';
import { Stream }  from '../stream';
import { IdeaService }  from '../idea.service';
import { SequenceService }  from '../sequence.service';
import { SequenceEngineService }  from '../sequence-engine.service';
import { TextChangedService }  from '../text-changed.service';
import { IconService }  from '../icon.service';
import { Idea }  from '../idea';

import { Canvas }  from './canvas';
import { StreamObjectBase }  from './stream-object-base';
import { Editor }  from './editor';
import { Text }  from './text';
import { IdeaObject, IdeaState }  from './idea-object';
import { TinyIdeaObject }  from './tiny-idea-object';
import { Icon }  from './icon';
import { ExpandButton }  from './button/expand-button';
import { HelpLayer }  from './help-layer';

import { Expandable }  from './interface/expandable';
import { Targetable }  from './interface/targetable';
import { Overable }  from './interface/overable';

//import { Favouritable }  from './interface/favouritable';

export class StreamObject extends StreamObjectBase implements Expandable, Overable {

  private editor: Editor;
  private _size: paper.Size;
  private me: Me;
  private layoutReady = false;
  private title: Text;
  private icon: Icon;
  private emoji: Text;
  private expandButton: ExpandButton;
  private titleBG: paper.Path.Rectangle;
  private background: paper.Path.Rectangle;
  private _help: HelpLayer;

  ideas: IdeaObject[] = [];

  constructor(
    editor: Editor,
    stream: Stream,
    me: Me,
    help: HelpLayer,
    private textChangedService: TextChangedService,
    private ideaService: IdeaService,
    private iconService: IconService,
    private sequenceService: SequenceService,
    private sequenceEngineService: SequenceEngineService,
    ) {

    super();
    this.me = me;
    this.editor = editor;
    this.stream = stream;
    this._help = help;

  }

  public typeName(): string {
    return "StreamObject";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {

    this._size = new Size(size.width, this.stream.open ? (IdeaObject.HEIGHT + StreamObjectBase.HEIGHT_EXTRA) : StreamObjectBase.COLLAPSED_SIZE);
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
    this.clipped = true;
    this.layout();

    if (!this.stream.emoji) {
      if (this.stream.icon != "internal:stream") {
        var icon = new Icon(this.iconService.getIcon(this.stream));
        icon.visible = false;
        icon.load(24, () => {
          icon.moveTo(new Point(this.bounds.x + 14, this.bounds.y + 18));
          icon.visible = true;
          this.icon = icon;
          this.addChild(icon);
        });
      }
    }

  }

  public size(): paper.Size {
    return this._size;
  }

  public setLayout() {
    this.layoutReady = true;
    this.layout();
  }

  public doLayout() {
    this.layout();
    this.editor.doLayout();
  }

  public pan(offset: paper.Size) {
    this.position.y += offset.height;
    this.ideas.forEach(i => {
      i.pan(new Size(offset.width, 0));
    });
  }

  public removeIdeas() {
    this.ideas.forEach(i => {
      i.release();
      i.remove();
    });
    this.ideas = [];
//    this.anyOverlap = false;
  }

  private hasDuration(idea: Idea): boolean {
    return idea.views && idea.views.timeline && idea.views.timeline.duration;
  }

  private sortIdeas(ideas: Idea[]): Idea[] {
    return  ideas.sort((a, b) => {
      if (this.hasDuration(a)) {
        if (this.hasDuration(b)) {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0
        }
        else {
          return -1;
        }
      }
      else {
        if (this.hasDuration(b)) {
          return 1;
        }
        else {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0
        }
      }
    });
  }

  private sortIdeasBackward(ideas: Idea[]): Idea[] {
    return  ideas.sort((a, b) => {
      return a.date < b.date ? 1 : a.date > b.date ? -1 : 0
    });
  }

  private buildIdeaRects(ideas: Idea[], rects: paper.Rectangle[], state: IdeaState) {
    this.sortIdeas(ideas).forEach(e => {
      let x = this.editor.timeAxis.toPos(moment(e.date));
      let size = IdeaObject.stateSize(state);
      if (e.views && e.views.timeline && e.views.timeline.duration) {
        var width = this.editor.timeAxis.toWidth(e.views.timeline.duration);
        if (width > IdeaObject.MIN_WIDTH) {
          size.width = width;
        }
        else {
          size.width = IdeaObject.MIN_WIDTH;
        }
      }
      let gap = state == IdeaState.Tiny ? 0 : 4;
      let r = this.findGap(rects, this.bounds.x + x, size.width, size.height, this.bounds.y + 16, size.height + gap);
      rects.push(r);
    });
  }

  public addIdeas(ideas: Idea[]) {
    if (ideas.length > 0) {
      var rects = [];
      var state = this.stream.open ? IdeaState.Normal : IdeaState.Tiny;
      this.buildIdeaRects(ideas, rects, state);
      for (var i=0; i<ideas.length; i++) {
        var rect = rects[i];
        if (state == IdeaState.Tiny) {
          if ((rect.y + rect.height) >= (this.bounds.y + this.bounds.height)) {
            rect.top = this.bounds.height - rect.height;
          }
        }
        else if (state == IdeaState.Normal) {
          var bottom = rect.bottom;
          rect.top = this.bounds.top;
          rect.height = bottom - rect.top;
        }
        this.addIdea(ideas[i], rect, state);
      }
    }
    this.layout();
  }

  public addOneIdea(idea: Idea): boolean {

    // don't add duplicates.
    if (this.ideas.find(e => e.idea._id == idea._id) != null) {
      return false;
    }

    let x = this.editor.timeAxis.toPos(moment(idea.date));
      var state = this.stream.open ? IdeaState.Normal : IdeaState.Tiny;
    let size = IdeaObject.stateSize(state);
    if (idea.views && idea.views.timeline && idea.views.timeline.duration) {
      var width = this.editor.timeAxis.toWidth(idea.views.timeline.duration);
      if (width > IdeaObject.MIN_WIDTH) {
        size.width = width;
      }
      else {
        size.width = IdeaObject.MIN_WIDTH;
      }
    }

    let rects = this.ideas.map(e => e.bounds);
    let r = this.findGap(rects, this.bounds.x + x, size.width, size.height, this.bounds.y + 16, size.height + (this.stream.open ? 4 : 0));
    this.addIdea(idea, r, state);

    return true;
  }

  private addIdea(idea: Idea, rect: paper.Rectangle, state: IdeaState) {
    var ideaobj;
    if (state == IdeaState.Tiny) {
      ideaobj = new TinyIdeaObject(this.editor, this.stream, idea);
    }
    else {
      ideaobj = new IdeaObject(this.editor, this.stream, idea);
    }
    ideaobj.rebuild(rect.topLeft, rect.size);
    this.addChild(ideaobj);
    this.ideas.push(ideaobj);
    ideaobj.addHelp(this._help);
  }

  private _build(): paper.Item[] {

 		var objs = [];
 		objs.push(this.empty());

    let size = this.size();

    {
      let rect = new Rectangle(new Point(0, 0), size);
      this.background = new Path.Rectangle(rect.expand(-1));
      this.background.closed = true;
      this.background.fillColor = new Color("#cccccc");
      this.background.opacity = 0.2;
      this.background.visible = this.stream.open;
//      this.background.visible = false;
      objs.push(this.background);
    }

    {
      let border = new paper.Path();
      border.strokeColor = new Color('grey');
      border.moveTo(new paper.Point(0, 0));
      border.lineTo(new paper.Point(size.width, 0));
      border.dashArray = [5, 10];
      objs.push(border);
    }

//     {
//       let rect = new Rectangle(new Point(0, 0), new Size(StreamObjectBase.TITLE_WIDTH, 32));
//       this.titleBG = new Path.Rectangle(rect, new Size(3, 3));
//       this.titleBG.closed = true;
//       this.titleBG.fillColor = new Color("white");
//       this.titleBG.opacity = 0.8;
//       objs.push(this.titleBG);
//     }

    if (this.stream.emoji) {
      this.emoji = new Text(new Point(0, 0));
      this.emoji.fillColor = new Color("black");
      this.emoji.content = this.stream.emoji;
	    this.emoji.fontSize = "24px";
      objs.push(this.emoji);
    }

    {
      this.title = new Text(new Point(0, 0));
//      this.title.wrapText(this.stream.name, StreamObjectBase.TITLE_WIDTH);
      this.title.clipText(this.stream.name, this.bounds.width);
	    this.title.fontSize = "14px";
      objs.push(this.title);
    }

    {
      this.expandButton = new ExpandButton(this.stream.open, true);
      this.expandButton.rebuild(new Point(0, 0), new Size(0, 0));
      objs.push(this.expandButton);
    }
    return objs;

  }

  private streamBgColor(): string {
    return this.editor.site ? (this.editor.site.streamBgColor ? this.editor.site.streamBgColor : "#7096cc") : "#eeeeee";
  }

  private dateColor(): string {
    return this.editor.site ? (this.editor.site.dateColor ? this.editor.site.dateColor : "#d3d3d3") : "#000000";
  }

  public bringAllToFront() {
//    this.titleBG.bringToFront();
    if (this.icon) {
      this.icon.bringToFront();
    }
    if (this.emoji) {
      this.emoji.bringToFront();
    }
    this.title.bringToFront();
    this.expandButton.bringToFront();
  }

  public layout() {

    if (!this.layoutReady) {
      return;
    }

    if (this.stream.open) {
      let highest = 0;
      this.ideas.forEach(i => {
        var newheight = (i.bounds.y - this.bounds.y) + i.bounds.height;
        if (newheight > highest) {
          highest = newheight;
        }
      });

      highest + 24;
      this._size.height = highest > 0 ? highest + StreamObjectBase.HEIGHT_EXTRA : IdeaObject.HEIGHT + StreamObjectBase.HEIGHT_EXTRA;
      if (this._size.height < StreamObjectBase.COLLAPSED_SIZE) {
        this._size.height = StreamObjectBase.COLLAPSED_SIZE;
      }
      this.resizeItemTo(this.children[0], this._size);
      this.resizeItemTo(this.background, this._size);
    }
    else {
      if (this._size.height != StreamObjectBase.COLLAPSED_SIZE) {
        this._size.height = StreamObjectBase.COLLAPSED_SIZE;
        this.resizeItemTo(this.children[0], this._size);
        this.resizeItemTo(this.background, this._size);
      }
    }

    {
      let rect = (this.children[0] as paper.Path.Rectangle);
      this.moveItemTo(rect, new Point(rect.bounds.x, this.bounds.y));
    }
    {
      let rect = (this.children[1] as paper.Path);
      this.moveItemTo(rect, new Point(rect.bounds.x, this.bounds.y));
    }

    this.moveItemTo(this.expandButton, new Point(this.bounds.x + this.bounds.width - 32, this.bounds.y + 4));

//    var top = (StreamObjectBase.STREAMBG_HEIGHT - this.title.bounds.height) / 2;
    this.moveItemTo(this.title, new Point(this.bounds.x + ((this.stream.icon != "internal:stream") || this.stream.emoji ? 30 : 4), this.bounds.y + 2));

    if (this.icon) {
      this.icon.moveTo(new Point(this.bounds.x + 14, this.bounds.y + 18));
    }
    if (this.emoji) {
      this.moveItemTo(this.emoji, new Point(this.bounds.x + 2, this.bounds.y + 4));
    }

  }

  public addHelp() {

    let size = this.size();
    this._help.addSpec("stream-expand", "Click here or double click on the stream to expand or contract the stream to see the events.", new Point(size.width - 240, 33 + 18));

  }

  private findGap(siblings, x, width, height, top, delta) {

		var p = new paper.Point(x, top);
		var low = new paper.Rectangle(p, p.add(new Point(width, height)));

		while (this.hits(low, siblings)) {
			low.y += delta;
		}
		return low;
	}

	private hits(r: paper.Rectangle, siblings: paper.Rectangle[]) {
		for (var i in siblings) {
			if (siblings[i].equals(r) || siblings[i].intersects(r)) {
				return true;
			}
		}
		return false;
	}

  // Box
  public wasHit(item: paper.Item, drag: boolean): boolean {
    return item != this.background;
  }

  // Indexable
  getIndex(): number {
    return this.streamIndex;
  }
  setIndex(index: number) {
    this.streamIndex = index;
//    this.title.wrapText((index + 1) + ". " + this.stream.name, StreamObjectBase.TITLE_WIDTH);
//    this.title.wrapText(this.stream.name, StreamObjectBase.TITLE_WIDTH);
    this.title.clipText(this.stream.name, this.bounds.width);
  }

  private finishExpand() {
    this.background.visible = this.stream.open;
    var ideas = this.ideas.map(e => e.idea);
    this.removeIdeas();
    this.addIdeas(ideas);
    this.doLayout();
    this.bringAllToFront();
    this.expandButton.setState(this.stream.open);
  }

  toggleExpand() {
    this.stream.open = !this.stream.open;
    this.finishExpand();
  }

  public doExpand() {
    this.stream.open = true;
    this.finishExpand();
  }

  public doContract() {
    this.stream.open = false;
    this.finishExpand();
  }

  // Expandable
  expand(canvas: Canvas) {
    if (canvas.isShiftDown()) {
      (canvas as Editor).expandAll();
    }
    else {
      this.doExpand();
    }
  }
  contract(canvas: Canvas) {
    if (canvas.isShiftDown()) {
      (canvas as Editor).contractAll();
    }
    else {
      this.doContract();
    }
  }

	// Overable
	over(canvas: Canvas, position: paper.Point) {
	  this.editor.closePopup();
	}

}
