import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';

import { BackendService } from './backend.service';
import { Idea }  from './idea';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';

@Injectable()
export class IdeaService extends BackendService {

  private ideasUrl = '/rest/1.0/ideas'

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
  ) {
    super(dialog, socketService, upService, http)
  }

  getIdeas(start: string, end: string, streams: string[], map: boolean): Observable<HttpResponse<any>> {
    var encstart = encodeURIComponent(start);
    var encend = encodeURIComponent(end);
    var url = `${this.ideasUrl}?start=${encstart}&end=${encend}`;
    if (streams) {
      url += "&streams=" + streams.join(",");
    }
    if (map) {
      url += "&views.map.lat_long=true";
    }
    return this.http.get<any>(url, { observe: 'response' })
    .pipe(
      catchError(this.handleResponseError<any>('getIdeas', []))
    );
  }

  getIdea(id: string): Observable<Idea> {
    const url = `${this.ideasUrl}/${id}?v=lean`;
    return this.http.get<Idea>(url).pipe(
      catchError(this.handleError<Idea>(`getIdea id=${id}`))
    );
  }

  getQR(id: string, varname: string): Observable<any> {
    var url = `${this.ideasUrl}/${id}/qr/${varname}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError<any>(`getQR id=${id}`))
    );
  }

}
