
import { Size, Point } from 'paper';

import { Box }  from '../box';
import { Text }  from '../text';
import { Canvas }  from '../canvas';
import { Icons }  from '../icons';
import { IconButton }  from './icon-button';

import { Linkable, instanceOfLinkable }  from '../interface/linkable';

export class LinkButton extends IconButton {

  constructor() {

    super(16);

  }

  public typeName(): string {
    return "LinkButton";
  }

  public setContent() {
    this.setIcon(Icons.LINK);
  }

  // Clickable
	public click(canvas: Canvas) {
	  if (instanceOfLinkable(this.parent)) {
	    (this.parent as Linkable).linkTo();
	  }
	}

}
