<as-split direction="{{splitDirection}}" [style.height]="fullHeight" (dragStart)="showIframeHider = true" (dragEnd)="resizeSplit($event)">
  <as-split-area [size]="splitPos">
    <canvas id="canvas" #canvas [style.top]="canvasTop" [style.width]="timelineWidth" [style.height]="timelineHeight"></canvas>
    <input type="text"id="textInput" name="textInput" #textInput>
  </as-split-area>
  <as-split-area [size]="100-splitPos">
    <button *ngIf="canClose()" class="close" [style.top]="closeTop" mat-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
<!--     <iframe id="convo" #convo src="/#/test?embed=true" width="100%" height="100%" frameborder="0"></iframe> -->
    <iframe id="convo" #convo src="/apps/conversations/#/ideas/none?captive=true" width="100%" height="100%" frameborder="0"></iframe>
    <div [hidden]="showIframeHider === false" class="hack-iframe-hider"></div>
  </as-split-area>
</as-split>
