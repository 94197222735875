
import { Canvas }  from '../canvas';

export interface Closable {

  close(canvas: Canvas);

}

export function instanceOfClosable(object: any): object is Closable {
  return 'close' in object;
}
