
import { Size, Point, Item, Rectangle, Path, Color, Group, Raster } from 'paper';

import * as moment from 'moment';

import { Me }  from '../me';
import { Stream }  from '../stream';
import { IdeaService }  from '../idea.service';
import { SequenceService }  from '../sequence.service';
import { SequenceEngineService }  from '../sequence-engine.service';
import { TextChangedService }  from '../text-changed.service';
import { IconService }  from '../icon.service';
import { Idea }  from '../idea';

import { Canvas }  from './canvas';
import { StreamObjectBase }  from './stream-object-base';
import { Editor }  from './editor';
import { Text }  from './text';
import { TinyIdeaObjectVert }  from './tiny-idea-object-vert';
import { Icon }  from './icon';
import { HelpLayer }  from './help-layer';

import { Targetable }  from './interface/targetable';
import { Overable }  from './interface/overable';

export class StreamObjectVert extends StreamObjectBase implements Overable {

  private editor: Editor;
  private _size: paper.Size;
  private me: Me;
  private layoutReady = false;
  private title: Text;
  private icon: Icon;
  private emoji: Text;
  private titleBG: paper.Path.Rectangle;
  private background: paper.Path.Rectangle;
  private _help: HelpLayer;

  ideas: TinyIdeaObjectVert[] = [];

  constructor(
    editor: Editor,
    stream: Stream,
    me: Me,
    help: HelpLayer,
    private textChangedService: TextChangedService,
    private ideaService: IdeaService,
    private iconService: IconService,
    private sequenceService: SequenceService,
    private sequenceEngineService: SequenceEngineService,
    ) {

    super();
    this.me = me;
    this.editor = editor;
    this.stream = stream;
    this._help = help;

  }

  public typeName(): string {
    return "StreamObjectVert";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {

    this._size = new Size(StreamObjectBase.COLLAPSED_SIZE, size.height);
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
    this.clipped = true;
    this.layout();

    if (!this.stream.emoji) {
      if (this.stream.icon != "internal:stream") {
        var icon = new Icon(this.iconService.getIcon(this.stream));
        icon.visible = false;
        icon.load(24, () => {
          icon.moveTo(new Point(this.bounds.x + 18, this.bounds.y + 24));
          icon.visible = true;
          this.icon = icon;
          this.addChild(icon);
        });
      }
    }

  }

  public size(): paper.Size {
    return this._size;
  }

  public setLayout() {
    this.layoutReady = true;
    this.layout();
  }

  public doLayout() {
    this.layout();
    this.editor.doLayout();
  }

  public pan(offset: paper.Size) {
    this.position.x += offset.width;
    this.ideas.forEach(i => {
      i.pan(new Size(0, offset.height));
    });
  }

  public removeIdeas() {
    this.ideas.forEach(i => {
      i.release();
      i.remove();
    });
    this.ideas = [];
//    this.anyOverlap = false;
  }

  private hasDuration(idea: Idea): boolean {
    return idea.views && idea.views.timeline && idea.views.timeline.duration;
  }

  private sortIdeas(ideas: Idea[]): Idea[] {
    return  ideas.sort((a, b) => {
      if (this.hasDuration(a)) {
        if (this.hasDuration(b)) {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0
        }
        else {
          return -1;
        }
      }
      else {
        if (this.hasDuration(b)) {
          return 1;
        }
        else {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0
        }
      }
    });
  }

  private sortIdeasBackward(ideas: Idea[]): Idea[] {
    return  ideas.sort((a, b) => {
      return a.date < b.date ? 1 : a.date > b.date ? -1 : 0
    });
  }

  private buildIdeaRects(ideas: Idea[], rects: paper.Rectangle[]) {
    this.sortIdeas(ideas).forEach(e => {
      let y = this.editor.timeAxis.toPos(moment(e.date));
      let size = new Size(TinyIdeaObjectVert.TINY_WIDTH, TinyIdeaObjectVert.TINY_HEIGHT);
      if (e.views && e.views.timeline && e.views.timeline.duration) {
        var height = this.editor.timeAxis.toHeight(e.views.timeline.duration);
        if (height > TinyIdeaObjectVert.MIN_HEIGHT) {
          size.height = height;
        }
        else {
          size.height = TinyIdeaObjectVert.MIN_HEIGHT;
        }
      }
      let gap = 0;
      let r = this.findGap(rects, this.bounds.y + y, size.width, size.height, this.bounds.x + 28, size.width + gap);
      rects.push(r);
    });
  }

  public addIdeas(ideas: Idea[]) {
    if (ideas.length > 0) {
      var rects = [];
      this.buildIdeaRects(ideas, rects);
      for (var i=0; i<ideas.length; i++) {
        var rect = rects[i];
        if ((rect.x + rect.width) >= (this.bounds.x + this.bounds.width)) {
          rect.left = this.bounds.width - rect.width;
        }
        this.addIdea(ideas[i], rect);
      }
    }
    this.layout();
  }

  public addOneIdea(idea: Idea): boolean {

    // don't add duplicates.
    if (this.ideas.find(e => e.idea._id == idea._id) != null) {
      return false;
    }

    let y = this.editor.timeAxis.toPos(moment(idea.date));
    let size = new Size(TinyIdeaObjectVert.TINY_WIDTH, TinyIdeaObjectVert.TINY_HEIGHT);
    if (idea.views && idea.views.timeline && idea.views.timeline.duration) {
      var height = this.editor.timeAxis.toHeight(idea.views.timeline.duration);
      if (height > TinyIdeaObjectVert.MIN_HEIGHT) {
        size.height = height;
      }
      else {
        size.height = TinyIdeaObjectVert.MIN_HEIGHT;
      }
    }

    let rects = this.ideas.map(e => e.bounds);
    let r = this.findGap(rects, this.bounds.y + y, size.width, size.height, this.bounds.x + 28, size.width);
    this.addIdea(idea, r);

    return true;
  }

  private addIdea(idea: Idea, rect: paper.Rectangle) {
    var ideaobj = new TinyIdeaObjectVert(this.editor, this.stream, idea);
    ideaobj.rebuild(rect.topLeft, rect.size);
    this.addChild(ideaobj);
    this.ideas.push(ideaobj);
    ideaobj.addHelp(this._help);
  }

  private _build(): paper.Item[] {

 		var objs = [];
 		objs.push(this.empty());
// 		objs.push(this.debug());

    let size = this.size();

    {
      let border = new paper.Path();
      border.strokeColor = new Color('grey');
      border.moveTo(new paper.Point(0, 0));
      border.lineTo(new paper.Point(0, size.height));
      border.dashArray = [5, 10];
      objs.push(border);
    }

/*
    {
      this.favButton = new FavouriteButton(this.stream.favorite);
      this.favButton.rebuild(new Point(0, 0), new Size(0, 0));
      objs.push(this.favButton);
    }
*/
//     {
//       let rect = new Rectangle(new Point(0, 0), new Size(StreamObjectBase.TITLE_WIDTH, 32));
//       this.titleBG = new Path.Rectangle(rect, new Size(3, 3));
//       this.titleBG.closed = true;
//       this.titleBG.fillColor = new Color("white");
//       this.titleBG.opacity = 0.8;
//       objs.push(this.titleBG);
//     }

    if (this.stream.emoji) {
      this.emoji = new Text(new Point(0, 0));
      this.emoji.fillColor = new Color("black");
      this.emoji.content = this.stream.emoji;
	    this.emoji.fontSize = "24px";
      objs.push(this.emoji);
    }

    {
//      this.title = new Text(new Point((this.stream.icon != "internal:stream") || this.stream.emoji ? 30 : 4, 0));
      var textPos = new Point(2, (this.stream.icon != "internal:stream") || this.stream.emoji ? 40 : 0);
      this.title = new Text(textPos);
      this.title.wrapText(this.stream.name, StreamObjectBase.TITLE_WIDTH);
//      this.title.content = this.stream.name;
	    this.title.fontSize = "14px";
	    this.title.rotate(90, textPos);
      objs.push(this.title);
    }

    return objs;

  }

  private streamBgColor(): string {
    return this.editor.site ? (this.editor.site.streamBgColor ? this.editor.site.streamBgColor : "#7096cc") : "#eeeeee";
  }

  private dateColor(): string {
    return this.editor.site ? (this.editor.site.dateColor ? this.editor.site.dateColor : "#d3d3d3") : "#000000";
  }

  public bringAllToFront() {
//    this.titleBG.bringToFront();
    if (this.icon) {
      this.icon.bringToFront();
    }
    if (this.emoji) {
      this.emoji.bringToFront();
    }
    this.title.bringToFront();
  }

  public layout() {

    if (!this.layoutReady) {
      return;
    }

    if (this._size.width != StreamObjectBase.COLLAPSED_SIZE) {
      this._size.width = StreamObjectBase.COLLAPSED_SIZE;
      this.resizeItemTo(this.children[0], this._size);
      this.resizeItemTo(this.background, this._size);
    }

    {
      let rect = (this.children[0] as paper.Path.Rectangle);
      this.moveItemTo(rect, new Point(this.bounds.x, rect.bounds.y));
    }
    {
      let rect = (this.children[1] as paper.Path);
      this.moveItemTo(rect, new Point(this.bounds.x, rect.bounds.y));
    }

    // text is rotated 90!
    this.moveItemTo(this.title, new Point(this.bounds.x + 2, this.bounds.y + ((this.stream.icon != "internal:stream") || this.stream.emoji ? 40 : 0)));

    if (this.icon) {
      this.icon.moveTo(new Point(this.bounds.x + 14, this.bounds.y + 18));
    }
    if (this.emoji) {
      this.moveItemTo(this.emoji, new Point(this.bounds.x + 2, this.bounds.y + 4));
    }

  }

  public addHelp() {

    let size = this.size();
    this._help.addSpec("stream-expand", "Click here or double click on the stream to expand or contract the stream to see the events.", new Point(size.width - 240, 33 + 18));

  }

  private findGap(siblings, y, width, height, left, delta) {

		var p = new paper.Point(left, y);
		var low = new paper.Rectangle(p, p.add(new Point(width, height)));

		while (this.hits(low, siblings)) {
			low.x += delta;
		}
		return low;
	}

	private hits(r: paper.Rectangle, siblings: paper.Rectangle[]) {
		for (var i in siblings) {
			if (siblings[i].equals(r) || siblings[i].intersects(r)) {
				return true;
			}
		}
		return false;
	}

  // Box
  public wasHit(item: paper.Item, drag: boolean): boolean {
    return item != this.background;
  }

  // Indexable
  getIndex(): number {
    return this.streamIndex;
  }
  setIndex(index: number) {
    this.streamIndex = index;
//    this.title.wrapText((index + 1) + ". " + this.stream.name, StreamObjectBase.TITLE_WIDTH);
    this.title.wrapText(this.stream.name, StreamObjectBase.TITLE_WIDTH);
  }

	// Overable
	over(canvas: Canvas, position: paper.Point) {
	  this.editor.closePopup();
	}

}
