<div fxLayout='column' [style.height]="height" (window:resize)="onResize($event)">
  <mat-toolbar *ngIf="!embed">
    <div class="build">{{version()}}</div>
    <div class="header-left">
      <img src="{{imageSrc()}}" class="main-icon">
      <div class="main-logo">{{headerTitle()}}</div>
    </div>
    <div class="center">
      <div *ngIf="needsNewBuild" class="reload">
        A new version of the UI is available! Hold the SHIFT key down and press reload on the browser to see it.
      </div>
      <div class="query">
        <button *ngIf="showFavorites()" class="favorite" mat-raised-button name="favorite" (click)="closeSearch(); searchBox.value = ''">
          <mat-icon>star</mat-icon>
        </button>
        <div *ngIf="site && site.timelineShowSearchBar" class="query-box">
          <input matInput class="mat-form-field query-input" [ngClass]="{ 'query-advanced': advanced }" #searchBox name="query" (keyup)="search(searchBox.value)" placeholder="Search" autocomplete="off"/>
          <div class="advanced-panel" *ngIf="advanced">
            <p class="label2">At</p>
            <span class="date">
              <input matInput [matDatepicker]="picker1" [value]="date">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </span>
            <p class="label3">Within</p>
            <mat-slider thumbLabel [(ngModel)]="range" [displayWith]="formatMins" min="15" max="43200"></mat-slider>
            <p class="range">{{rangeText()}}</p>
            <button class="search-button" mat-raised-button name="advanced-search" (click)="advancedSearch(searchBox.value);">
              Search
            </button>
           </div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <span class="username">
        <span *ngIf="me && me.name != 'public'" class="user">Welcome <b>{{me ? (me.fullname ? me.fullname : me.name) : ""}}</b>
          <button mat-button name="me" class="me-button">
            <img *ngIf="me" [ngClass]="{ 'user-icon': me.image, 'icon': !me.image }" src="{{getIcon(me)}}">
          </button>
        </span>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="gotoApp('conversations')">
            {{convoLinkName()}}
          </button>
          <button *ngIf="me && me.name != 'public' && site && site.timelineShowSearchBar" mat-menu-item (click)="switchSearchMode(); searchBox.value = ''">
            <span *ngIf="!advanced">Advanced</span>
            <span *ngIf="advanced">Simple</span>
          </button>
          <button mat-menu-item (click)="gotoApp('map')">
            Map
          </button>
          <button *ngIf="!inIOS() && me && me.name != 'public'" mat-menu-item (click)="gotoApp('data')">
            Data
          </button>
          <button *ngIf="me && me.name != 'public'" mat-menu-item (click)="gotoApp('admin', false)">
            Admin
          </button>
          <button *ngIf="me && me.name == 'public'" mat-menu-item (click)="gotoApp('login')">
            Login
          </button>
          <button *ngIf="me && me.name != 'public'" mat-menu-item (click)="logoff()">
            Logoff
          </button>
        </mat-menu>
      </span>
    </div>
  </mat-toolbar>
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
