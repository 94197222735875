import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSplitModule } from 'angular-split';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';

import { RestErrorComponent } from './rest-error/rest-error.component';
import { IdeaService }  from './idea.service';
import { MeService }  from './me.service';
import { SequenceService }  from './sequence.service';
import { SequenceEngineService }  from './sequence-engine.service';
import { SocketService }  from './socket.service';
import { StreamService }  from './stream.service';
import { IconService }  from './icon.service';
import { TextChangedService }  from './text-changed.service';
import { TimelineComponent } from './timeline/timeline.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { SiteService }  from './site.service';
import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';
import { SettingsService }  from './settings.service';
import { StreamsDialogComponent } from './streams-dialog/streams-dialog.component';
import { UIBuildService }  from './uibuild.service';
import { UpService }  from './up.service';
import { SecurityErrorComponent } from './security-error/security-error.component';
import { SearchQueryService }  from './search-query.service';
import { QrDialogComponent } from './qr-dialog/qr-dialog.component';
import { NewStreamService }  from './new-stream.service';
import { TestComponent } from './test/test.component';

@NgModule({
  declarations: [
    AppComponent,
    RestErrorComponent,
    TimelineComponent,
    ConfirmComponent,
    SystemMaintenanceComponent,
    StreamsDialogComponent,
    SecurityErrorComponent,
    QrDialogComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    ClipboardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSliderModule,
    MatBadgeModule,
    AngularSplitModule,
  ],
  providers: [SequenceService, SocketService, TextChangedService, IdeaService,
    StreamService, IconService, SequenceEngineService, MeService, SiteService,
    SettingsService, UIBuildService, UpService, SearchQueryService, NewStreamService],
  bootstrap: [AppComponent]
})
export class AppModule { }
