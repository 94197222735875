import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  height = "500px";

  private embed = false;

  constructor(
    private route: ActivatedRoute,
  ) {
    route.queryParams.subscribe(params => {
      this.embed = params['embed'] == "true";
    });
  }

  ngOnInit(): void {
    this.onResize(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.height = (window.innerHeight - (this.embed ? 0 : 64)) + "px";

  }

}
