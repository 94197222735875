
import { Size, Point } from 'paper';

import { Box }  from '../box';
import { Text }  from '../text';
import { Canvas }  from '../canvas';
import { Icons }  from '../icons';
import { IconButton }  from './icon-button';

import { Expandable, instanceOfExpandable }  from '../interface/expandable';

export class ExpandButton extends IconButton {

  private open: boolean;

  constructor(open: boolean, large: boolean = false) {

    super(large ? 24 : 16, "#dadada", "black");
    this.open = open;

  }

  public typeName(): string {
    return "ExpandButton";
  }

  public setContent() {
    this.setIcon(this.open ? Icons.EXPAND : Icons.CONTRACT);
  }

  public setState(open: boolean) {
    this.open = open;
    this.setContent();
  }

  // Clickable
	public click(canvas: Canvas) {
	  if (instanceOfExpandable(this.parent)) {
	    this.open = !this.open;
      this.setContent();
	    if (this.open) {
	      (this.parent as Expandable).expand(canvas);
	    }
	    else {
	      (this.parent as Expandable).contract(canvas);
	    }
	  }
	}

}
