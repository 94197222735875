
import { UIBits } from './uibits';

export class UIFlags {

  showMyQ: boolean;
  hideStreamsWhenPublic: boolean;
  canUseSketch: boolean;
  sketchIsAdvanced: boolean;
  showName: boolean;
  showIdeaMenuButton: boolean;
  showDeleteIdea: boolean;
  showArchiveIdea: boolean;
  showMoveToStreamOnIdea: boolean;
  showActionIdea: boolean;
  showInfoOnIdea: boolean;
  buttonView: boolean;
  disableChat: boolean;
  disableActions: boolean;
  aggressiveFilter: boolean;
  hideAAG: boolean;
  hidePager: boolean;
  showArchiveStream: boolean;
  showAdmin: boolean;
  showNewStream: boolean;
  showShareStream: boolean;
  
  constructor(bits: UIBits) {
    this.update(bits);
  }

  update(bits: UIBits): void {
    this.showMyQ = (bits & UIBits.showMyQ) != 0;
    this.hideStreamsWhenPublic = (bits & UIBits.hideStreamsWhenPublic) != 0;
    this.canUseSketch = (bits & UIBits.canUseSketch) != 0;
    this.sketchIsAdvanced = (bits & UIBits.sketchIsAdvanced) != 0;
    this.showName = (bits & UIBits.showName) != 0;
    this.showIdeaMenuButton = (bits & UIBits.showIdeaMenuButton) != 0;
    this.showDeleteIdea = (bits & UIBits.showDeleteIdea) != 0;
    this.showArchiveIdea = (bits & UIBits.showArchiveIdea) != 0;
    this.showMoveToStreamOnIdea = (bits & UIBits.showMoveToStreamOnIdea) != 0;
    this.showActionIdea = (bits & UIBits.showActionIdea) != 0;
    this.showInfoOnIdea = (bits & UIBits.showInfoOnIdea) != 0;
    this.buttonView = (bits & UIBits.buttonView) != 0;
    this.disableChat = (bits & UIBits.disableChat) != 0;
    this.disableActions = (bits & UIBits.disableActions) != 0;
    this.aggressiveFilter = (bits & UIBits.aggressiveFilter) != 0;
    this.hideAAG = (bits & UIBits.hideAAG) != 0;
    this.hidePager = (bits & UIBits.hidePager) != 0;
    this.showArchiveStream = (bits & UIBits.showArchiveStream) != 0;
    this.showAdmin = (bits & UIBits.showAdmin) != 0;
    this.showNewStream = (bits & UIBits.showNewStream) != 0;
    this.showShareStream = (bits & UIBits.showShareStream) != 0;
  }

  bits(): UIBits {
    var bits = UIBits.none;
    bits |= this.showMyQ ? UIBits.showMyQ : 0;
    bits |= this.hideStreamsWhenPublic ? UIBits.hideStreamsWhenPublic : 0;
    bits |= this.canUseSketch ? UIBits.canUseSketch : 0;
    bits |= this.sketchIsAdvanced ? UIBits.sketchIsAdvanced : 0;
    bits |= this.showName ? UIBits.showName : 0;
    bits |= this.showIdeaMenuButton ? UIBits.showIdeaMenuButton : 0;
    bits |= this.showDeleteIdea ? UIBits.showDeleteIdea : 0;
    bits |= this.showArchiveIdea ? UIBits.showArchiveIdea : 0;
    bits |= this.showMoveToStreamOnIdea ? UIBits.showMoveToStreamOnIdea : 0;
    bits |= this.showActionIdea ? UIBits.showActionIdea : 0;
    bits |= this.showInfoOnIdea ? UIBits.showInfoOnIdea : 0;
    bits |= this.buttonView ? UIBits.buttonView : 0;
    bits |= this.disableChat ? UIBits.disableChat : 0;
    bits |= this.disableActions ? UIBits.disableActions : 0;
    bits |= this.aggressiveFilter ? UIBits.aggressiveFilter : 0;
    bits |= this.hideAAG ? UIBits.hideAAG : 0;
    bits |= this.hidePager ? UIBits.hidePager : 0;
    bits |= this.showArchiveStream ? UIBits.showArchiveStream : 0;
    bits |= this.showAdmin ? UIBits.showAdmin : 0;
    bits |= this.showNewStream ? UIBits.showNewStream : 0;
    bits |= this.showShareStream ? UIBits.showShareStream : 0;
    return bits;
  }

}
