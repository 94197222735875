import { Size, Point } from 'paper';

import { Box }  from '../box';
import { Canvas }  from '../canvas';
import { Targetable }  from './targetable';

export interface Dragable {

	draggingStarted(canvas: Canvas);
	dragBy(canvas: Canvas, delta: paper.Size);
	dragOver(dragable: Dragable);
	endDrag(canvas: Canvas, start: paper.Point, target: Targetable);
	draggedOver(targetable: Targetable): boolean;
	shouldBringForward(): boolean;

}

export function instanceOfDragable(object: any): object is Dragable {
  return 'dragBy' in object;
}
