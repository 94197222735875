
import { Size, Point, Rectangle, Item } from 'paper';

import { Box }  from './box';
import { InsertPoint }  from './insert-point';

import { Indexable }  from './interface/indexable';
import { Reorderable }  from './interface/reorderable';

export class ReorderHelper {

  private spacing = 0;
  private inset: paper.Point;
  private gap = -1;
  private lastDrag = -1;
  private lastOver = -1;
  private objs: Indexable[];
  private insertPoint: InsertPoint;
  private box: Reorderable;

  constructor(box: Reorderable, spacing: number, inset: paper.Point) {
    this.box = box;
    this.spacing = spacing;
    this.inset = inset;
  }

  public build(objs: paper.Item[], width: number, iobjs: Indexable[]) {
    this.insertPoint = new InsertPoint();
    this.insertPoint.rebuild(new Point(2, 1), new Size(width - 4, 0));
    this.insertPoint.visible = false;
    objs.push(this.insertPoint);
    this.setObjs(iobjs);
  }

  public setObjs(iobjs: Indexable[]) {
    this.objs = iobjs;
    for (var i=0; i<this.objs.length; i++) {
      this.objs[i].setIndex(i);
    }
  }

  // Insertable
  public hilightInsert(item: paper.Item) {
    this.insertPoint.visible = true;
    this.insertPoint.bringToFront();
    var pos: number;
    if (item) {
      pos = item.bounds.y - 2;
    }
    else {
      if (this.objs.length > 0) {
        let last = (this.objs[this.objs.length-1] as any) as paper.Item;
        pos = last.bounds.y + last.bounds.height + 2;
      }
      else {
        pos = this.box.getBounds().y;
      }
    }
    this.insertPoint.moveTo(new Point(this.box.getBounds().x + this.inset.x, pos));
  }

  public insertAt(box: Box, data: any[], index: number, obj: any) {
    if (index < 0) {
      data.push(obj);
    }
    else {
      data.splice(index, 0, obj);
    }
    box.rebuild(new Point(box.bounds.x, box.bounds.y), box.size());
  }

  public endInsert() {
    this.insertPoint.visible = false;
  }

  // Reorderable
  public reorder(drag: number, over: number) {

    if (this.gap < 0) {
      this.gap = drag;
    }
    if (over == this.gap) {
      return;
    }

    let overbox = (this.objs[over] as any) as Box;

    this.lastDrag = drag;
    this.lastOver = over;
    this.moveBox(this.box.getBounds(), overbox, this.gap);
    this.gap = over;

  }

  public didReorder(): boolean {
    return this.gap >= 0;
  }

  public endReorder(data: any[]) {
    this.reorderData(data);
    let dragbox = (this.objs[this.lastDrag] as any) as Box;
    this.moveBox(this.box.getBounds(), dragbox, this.lastOver);
    this.reorderData(this.objs);
    this.gap = -1;
    for (var i=0; i<this.objs.length; i++) {
      this.objs[i].setIndex(i);
    }
  }

  private reorderData(data: any[]) {
    data.splice(this.lastOver, 0, data.splice(this.lastDrag, 1)[0]);
  }

  private moveBox(frame: paper.Rectangle, box: Box, index: number) {
    let pos = (box.bounds.height * index) + (this.spacing * index);
    box.moveTo(new Point(frame.x + this.inset.x, frame.y + this.inset.y + pos));
  }
}
