import { Injectable } from '@angular/core';

import * as sequenceEngine from 'sequence-engine';
import * as _ from 'lodash';

import { Sequence } from './sequence';
import { Me }  from './me';
import { Idea } from './idea';

@Injectable()
export class SequenceEngineService {

  constructor(
  )
  { }

  public extend(seq: any) {
    sequenceEngine.extend(seq, null, null, null);
  }

  public buildContext(idea: Idea, me: Me): any {

      let context = {};
			context["me"] = { id: me._id, name: me.fullname };

      if (idea) {
			  context["id"] = idea._id;
			  context["text"] = idea.text;
        _.forEach(idea.data, function(e) {
          context[e["var"]] = e.value;
        });
        context["date"] = idea.date;
        if (idea.stream) {
          context["stream"] = idea.stream;
        }
      }

			return context;
  }

}
