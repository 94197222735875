import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewStreamService {

  private newStreamSource = new Subject<string>();
  newStream$ = this.newStreamSource.asObservable();

  constructor() { }

  newStream(value: string) {
    this.newStreamSource.next(value);
  }

}
