
import { Size, Point, Item, PointText, Color } from 'paper';

import { Canvas }  from './canvas';
import { Box }  from './box';
import { Text }  from './text';
import { CloseButton }  from './button/close-button';
import { Editor }  from './editor';

import { Closable }  from './interface/closable';
import { Dragable }  from './interface/dragable';
import { Targetable }  from './interface/targetable';

export class HelpBubble extends Box implements Closable, Dragable {

  private _text: string;
  private _size: paper.Size;

  constructor(text: string) {

    super();
    this._text = text;
  }

  public typeName(): string {
    return "HelpLayer";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    this._size = new Size(200, 100);
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
    this.layout();
  }

  public size(): paper.Size {
    return this._size;
  }

  private _build(): paper.Item[] {

 		var objs = [];
    objs.push(this.shadow());
    objs.push(this.paletteBorder());
    objs.push(this.insetRect());

    let close = new CloseButton();
    close.rebuild(new Point(this.size().width - 26, 2), new Size(0, 0));
    objs.push(close);

    let text = new Text(new Point(8, 20));
		text.wrapText(this._text, this.size().width - 20);
    objs.push(text);

    return objs;

  }

  public layout() {
    this._size.height = this.children[4].bounds.height + 16;
    this.resizeItemTo(this.children[0], this._size);
    this.resizeItemTo(this.children[1], this._size);
    this.resizeItemTo(this.children[2], new Size(this._size.width - 4, this._size.height - 4));
  }

  // Closable
  public close(canvas: Canvas) {
    (canvas as Editor).hideHelp();
  }

  // Dragable
	public draggingStarted(canvas: Canvas) {
	}
	public dragBy(canvas: Canvas, delta: paper.Size) {
		this.position = this.position.add(new Point(delta.width, delta.height));
	}
	public dragOver(dragable: Dragable) {
	}
	public draggedOver(targetable: Targetable): boolean {
	  return false;
	}
	public endDrag(canvas: Canvas, start: paper.Point, target: Targetable) {
	}
	public shouldBringForward(): boolean {
	  return true;
	}

}
