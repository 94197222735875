import { Color } from 'paper';

export class ObjectColors {

  static allColors = [
    "white",
    "lightGrey",
    "darkGrey",
    "grey",
    "cyan",
    "yellow",
    "red",
    "green",
    "blue",
    "magenta",
    "orange",
    "purple",
    "brown",
    "black",
  ];
  static allWhiteTextColors = [
    "darkGrey",
    "grey",
    "red",
    "green",
    "blue",
    "magenta",
    "purple",
    "brown",
    "black",
  ];

  public static categoryColor(cat: string): string {
    switch (cat) {
    case "Lib": return "lightGreen";
    case "Control": return "lightCyan";
    case "Position": return "lightCyan";
    case "Compare": return "lightCyan";
    case "Data": return "lavenderBlush";
    case "Values": return "mistyRose";
    case "List": return "bisque";
    case "MoreList": return "bisque";
    case "Date": return "mistyRose";
    case "Process": return "lightSkyBlue";
    case "Math": return "seaShell";
    case "Aggregate": return "yellow";
    case "Empty": return "white";
    case "Import": return "yellowGreen";
    default: return "red";
    }
  }

  public static categoryTextColor(cat: string): string {
    switch (cat) {
    default: return "black";
    }
  }

  public static objectColor(cat: string): string {
    switch (cat) {
    case "code": return "aquamarine";
    case "element": return "khaki";
    case "sequence": return "yellowGreen";
    case "state": return "lightBlue";
    case "test": return "lemonChiffon";
    case "table": return "seaShell";
    case "integration": return "seaShell";
    case "variable": return "seaShell";
    case "handler": return "seaShell";
    default: return "red";
    }
  }

  public static blockColor(cat: string): string {
    switch (cat) {
    case "dictionary": return "antiqueWhite";
    case "string": return "ghostWhite";
    case "number": return "ghostWhite";
    case "bool": return "lightBlue";
    case "list": return "plum";
    case "null": return "lightPink";
    default: return "red";
    }
  }

  public static buttonColor(element: any): string {
    return element.styles && element.styles.color ? element.styles.color : "white";
  }

  public static buttonTextColor(element: any): string {
    if (element.disabled) {
      return "grey";
    }
    if (element.styles) {
      return ObjectColors.allWhiteTextColors.indexOf(element.styles.color) >= 0 ? "white" : "black";
    }
    return "black";
  }

  static textColorOrDisabled(element: any): string {
    if (element.disabled) {
      return "grey";
    }
    return ObjectColors.textColor(element);
  }

  static textColor(element: any): string {
    if (element.styles) {
      if (element.styles.color) {
        return element.styles.color;
      }
      return ObjectColors.allWhiteTextColors.indexOf(element.styles.bgColor) >= 0 ? "white" : "black";
    }
    return "black";
  }

  static hasBGColor(element: any): boolean {
    return element.styles != null && element.styles.bgColor != null;
  }

  static textBGColor(element: any): string {
    return element.styles && element.styles.bgColor ? element.styles.bgColor : "white";
  }

  static align(element: any): string {
    return element.styles && element.styles.align ? element.styles.align : "left";
  }

  static parseColor(colorstring: string, transparent: boolean): paper.Color {
    try {
      var color = JSON.parse(colorstring);
      if (Array.isArray(color)) {
        if (transparent) {
          return new Color(color[0] / 255, color[1] / 255, color[2] / 255, color[3]);
        }
        else {
          return new Color(color[0] / 255, color[1] / 255, color[2] / 255);
        }
      }
      return new Color(color);
    }
    catch {
      return new Color(colorstring);
    }
  }

}
