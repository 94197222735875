
import { Canvas }  from '../canvas';
import { Point } from 'paper';

export interface Overable {

  over(canvas: Canvas, position: paper.Point);

}

export function instanceOfOverable(object: any): object is Overable {
  return object && 'over' in object;
}
