
export class Sequence {

  _id: string;
  name: string;
  description: string;
  imports: any[];
  importNames: string[];
  views: any;
  library: any[];
  importLibrary: any[];
  integrations: any[];
  handlers: any[];
  scenarios: any[];
  variables: any[];
  props: any[];
  states: any[];
  tables: any[];
  aimi: any;
  modifyDate: string;
  policy: string;
  typeIcon: string;
  version: number;
  action: boolean;

}
