
import { Point, Size } from 'paper';

import { Button }  from './button';
import { Editor }  from '../editor';

export class HelpButton extends Button {

  constructor() {

    super();

  }

  public typeName(): string {
    return "HelpButton";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    super.rebuild(topLeft, size);
    this.setContent("Help");
  }

  public run(editor: Editor) {
    editor.showHelp();
  }

}
