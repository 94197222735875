
import { Canvas }  from '../canvas';

export interface DblClickable {

  dblClick(canvas: Canvas);

}

export function instanceOfDblClickable(object: any): object is DblClickable {
  return 'dblClick' in object;
}
