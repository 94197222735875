
import { Path, Point, Rectangle, Size, Color, PointText, Group, Item } from 'paper';

import { Box }  from '../box';
import { Text }  from '../text';
import { Canvas }  from '../canvas';

import { Clickable }  from '../interface/clickable';
import { Runable, instanceOfRunable }  from '../interface/runable';

export class Button extends Box implements Clickable {

  private _size: paper.Size;
  enabled = true;
  hilite = false;

  static HEIGHT: number = 24;

  constructor() {

    super();

  }

  public typeName(): string {
    return "Button";
  }

  public testName(): string {
    return "button";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    this._size = new Size(size.width, this.buttonHeight());
    this.removeChildren();
    this.addChildren(this.build());
    this.moveTo(topLeft);
    this.drawEnable();
  }

  public size(): paper.Size {
    return this._size;
  }

  public run(canvas: Canvas) {
    if (instanceOfRunable(this.parent)) {
      (this.parent as Runable).run(canvas, this);
    }
  }

  public textIndex(): number {
    return 1;
  }

  public setContent(name: string) {
    (this.children[this.textIndex()] as paper.PointText).content = name;
  }
  public content(): string {
    return (this.children[this.textIndex()] as paper.PointText).content;
  }

  public disable() {
    this.enabled = false;
    this.drawEnable();
  }

  public enable() {
    this.enabled = true;
    this.drawEnable();
  }

  public buttonColor(): string {
    return "white";
  }

  public textColor(): string {
    return "black";
  }

  public buttonHeight(): number {
    return Button.HEIGHT;
  }

  public bold() {
    (this.children[this.textIndex()] as Text).style.fontWeight = "bold";
  }

  public normal() {
    (this.children[this.textIndex()] as Text).style.fontWeight = "normal";
  }

  private drawEnable() {
    (this.children[this.textIndex()] as Text).fillColor = this.enabled ? new Color(this.textColor()) : new Color("grey");
  }

  public build(): paper.Item[] {

	var objs = [];
    objs.push(this.buttonBorder(this.buttonColor(), this.hilite ? 2 : 1));

    let size = this.size();
    let text = new Text(new Point(size.width / 2, (size.height / 2) + 4));
    text.justification = "center";
    text.content = "x";
    text.fillColor = new Color(this.textColor());
    objs.push(text);

    return objs;

  }

  // Clickable
  public drawOver() {
    if (this.enabled) {
      (this.children[0] as Text).fillColor = new Color("lightGrey");
    }
  }
  public drawOff() {
    (this.children[0] as Text).fillColor = new Color(this.buttonColor());
  }
  public click(canvas: Canvas) {
    if (this.enabled) {
      this.run(canvas);
    }
  }

}
