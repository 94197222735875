
import { Size, Point, Item, Rectangle, Path, Color } from 'paper';

import * as moment from 'moment';

import { Me }  from '../me';
import { Idea }  from '../idea';
import { Stream }  from '../stream';

import { Canvas }  from './canvas';
import { Editor }  from './editor';
import { Box }  from './box';
import { Text }  from './text';
import { ObjectColors }  from './object-colors';
import { HelpLayer }  from './help-layer';

import { Selectable }  from './interface/selectable';
import { DblClickable }  from './interface/dblclickable';
import { Overable }  from './interface/overable';

export enum IdeaState {
  Tiny,
  Normal
};

export class IdeaObject extends Box implements Selectable, Overable, DblClickable {

  private _size: paper.Size;
  private editor: Editor;
  private _text: Text;
  private _border: paper.Path.Rectangle;
  private _border2: paper.Path.Rectangle;

  stream: Stream;
  idea: Idea;

  static WIDTH: number = 420;
  static MIN_WIDTH: number = 120;
  static HEIGHT: number = 22;
  static TINY_WIDTH: number = 20;
  static TINY_HEIGHT: number = 12;
  static CLIP_WIDTH: number = 45;
  static WRAP_WIDTH: number = 30;

  constructor(editor: Editor, stream: Stream, idea: Idea,
  ) {

    super();
    this.editor = editor;
    this.stream = stream;
    this.idea = idea;

  }

  public typeName(): string {
    return "IdeaObject";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {

    this._size = size;
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
    this.layout();

  }

  static stateSize(state: IdeaState): paper.Size {
    var width: number;
    var height: number;
    switch (state) {
      case IdeaState.Tiny:
        width = IdeaObject.TINY_WIDTH;
        height = IdeaObject.TINY_HEIGHT;
        break;
      default:
        width = IdeaObject.WIDTH;
        height = IdeaObject.HEIGHT;
    }
    return new Size(width, height);
  }

  public size(): paper.Size {
    return this._size;
  }

  public pan(offset: paper.Size) {
    this.position.x += offset.width;
    this.position.y += offset.height;
  }

  public doLayout() {
    this.layout();
    if (this.parent instanceof Box) {
      (this.parent as Box).doLayout();
    }
  }

  private hasDuration(): boolean {
    return this.idea.views && this.idea.views.timeline && this.idea.views.timeline.duration;
  }

  public layout() {

    let size = this.size();

    // resize based on the size of the text.
    if (this._text.bounds.width < size.width) {
      if (this._border2) {
        this.resizeItemTo(this._border2, new Size(this._text.bounds.width + 10, IdeaObject.HEIGHT-2));
      }
      else if (!this.hasDuration()) {
        this.resizeItemTo(this._border, new Size(this._text.bounds.width + 10, IdeaObject.HEIGHT-2));
      }
    }

  }

  public addHelp(help: HelpLayer) {
  }

  private _build(): paper.Item[] {

 		var objs = [];
    objs.push(this.blank());
//    objs.push(this.debug());

    let size = this.size();
    var hasDuration = this.hasDuration();

    if (hasDuration) {
      let width = this.editor.timeAxis.toWidth(this.idea.views.timeline.duration);

      if (width < IdeaObject.MIN_WIDTH) {
        let rect = new Rectangle(new Point(0, size.height-20), new Size(IdeaObject.MIN_WIDTH, IdeaObject.HEIGHT-2));
        this._border2 = new Path.Rectangle(rect, new Size(3, 3));
        this._border2.closed = true;
        this._border2.fillColor = new Color("lightgrey");
        this._border2.strokeColor = new Color("grey");
        this._border2.strokeWidth = 1;
        objs.push(this._border2);
      }

      let rect = new Rectangle(new Point(0, size.height-20), new Size(width, IdeaObject.HEIGHT-2));
      this._border = new Path.Rectangle(rect, new Size(0, 0));
      this._border.closed = true;
      this._border.fillColor = this.idea.views && this.idea.views.all && this.idea.views.all.bgColor ? ObjectColors.parseColor(this.idea.views.all.bgColor, false) : new Color("white");
      this._border.strokeColor = this.idea.views && this.idea.views.all && this.idea.views.all.color ? ObjectColors.parseColor(this.idea.views.all.color, false) : new Color("black");
      this._border.strokeWidth = 1;
      objs.push(this._border);

    }
    else {
      {
        let rect = new Rectangle(new Point(0, 4), new Size(8, 8));
        var dot = new Path.Rectangle(rect, new Size(3, 3));
        dot.closed = true;
        dot.fillColor = new Color("grey");
        objs.push(dot);
      }

      {
        let line = new paper.Path();
        line.strokeColor = new Color("grey");
        line.moveTo(new paper.Point(4, 10));
        line.lineTo(new paper.Point(4, size.height-10));
        line.strokeWidth = 2;
        objs.push(line);
      }

      {
        let line = new paper.Path();
        line.strokeColor = new Color("grey");
        line.moveTo(new paper.Point(4, size.height-10));
        line.lineTo(new paper.Point(10, size.height-10));
        line.strokeWidth = 2;
        objs.push(line);
      }

      let rect = new Rectangle(new Point(10, size.height-20), new Size(size.width-10, 20));
      this._border = new Path.Rectangle(rect, new Size(5, 5));
      this._border.closed = true;
      this._border.fillColor = this.idea.views && this.idea.views.all && this.idea.views.all.bgColor ? ObjectColors.parseColor(this.idea.views.all.bgColor, false) : new Color("white");
      this._border.strokeColor = this.idea.views && this.idea.views.all && this.idea.views.all.color ? ObjectColors.parseColor(this.idea.views.all.color, false) : new Color("black");
      this._border.strokeWidth = 1;
      objs.push(this._border);
    }

    var top = size.height - 20;
    {
      this._text = new Text(new Point(hasDuration ? 6 : 16, top + 14));
      this._text.fillColor = new Color(this.idea.views && this.idea.views.all && this.idea.views.all.textColor ? this.idea.views.all.textColor : "black");
      this._text.fontWeight = "bold";
      this._text.clipText(this.idea.text, size.width-16);
      objs.push(this._text);
    }

    return objs;

  }

  // Selectable
  canSelect(): boolean {
    return true;
  }

  public select(canvas: Canvas) {
    this.children[1].strokeWidth = 2;
  }

  public deselect(canvas: Canvas) {
    this.children[1].strokeWidth = 1;
  }

  // DblClickable
	public dblClick(canvas: Canvas) {
    this.editor.showIdea(this.idea._id);
  }

	// Overable
	over(canvas: Canvas, position: paper.Point) {
	  this.editor.popupIdea(this.stream, this.idea, this.bounds, position);
	}

}
