
import * as moment from 'moment';

export class TimeRef {

  start: moment;
  end: moment;
  tickUnit = "";

  private oldStart: moment;
  private oldEnd: moment;
  private minStart: moment;
  private maxEnd: moment;

  public validate() {
    if (!(this.start && this.end)) {
      this.start = moment();
      this.end = this.start.clone();
      this.start.subtract(4, "days");
      this.end.add(4, "days");
      this.setRange();
    }
  }

  public setRange() {
    this.minStart = this.start.clone();
    this.maxEnd = this.end.clone();
  }

  public startPanning() {
    this.oldStart = this.start.clone();
    this.oldEnd = this.end.clone();
  }

  public pan(diff: moment) {

    this.start = this.start.subtract(diff);
    this.end = this.end.subtract(diff);

  }

  public endPan(callback: (start: moment, end: moment) => void): boolean {

    if (this.start < this.minStart) {
      this.minStart = moment(this.start);
      let sdiff = this.start.diff(this.oldStart);
      if (sdiff < 0) {
        let nstart = moment(this.start + sdiff);
        callback(nstart, this.oldStart);
        return true;
      }
    }
    if (this.end > this.maxEnd) {
      this.maxEnd = moment(this.end);
      let ediff = this.end.diff(this.oldEnd);
      if (ediff > 0) {
        let nend = moment(this.end + ediff);
        callback(this.oldEnd, nend);
        return true;
      }
    }
    return false;

  }

}
