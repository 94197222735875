import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BackendService } from './backend.service';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';
import { MeService }  from './me.service';
import { Me } from './me';

@Injectable()
export class SettingsService extends BackendService {

  private meUrl = '/rest/1.0/users/me';
  private hasStorage = false;

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
    private meService: MeService,
  ) {
    super(dialog, socketService, upService, http)
    this.hasStorage = typeof(Storage) !== "undefined";
  }

  getSettings(me: Me, app: string): Observable<any> {

    if (me.name == "public") {
      let settings = this.getAllLocalSettings();
      if (settings) {
        return of(settings[app]);
      }
      return of({});
    }
    else {
      return this.meService.getSettings(app);
    }

  }

  updateSettings(me: Me, app: string, appsettings: any): Observable<any> {

    if (me.name == "public") {

      let settings = this.getAllLocalSettings();
      if (!settings) {
        settings = {};
      }
      var newappsettings = settings[app];
      if (newappsettings) {
        for (var i in appsettings) {
          newappsettings[i] = appsettings[i];
        }
      }
      else {
        newappsettings = appsettings;
      }

      settings[app] = newappsettings;
      return this.setAllSettings(settings);

    }
    else {
      return this.meService.updateSettings(app, appsettings);
    }

  }

  clearSettings(me: Me, app: string): Observable<any> {

    if (me.name == "public") {

      let settings = this.getAllLocalSettings();
      if (!settings) {
        settings = {};
      }
      settings[app] = {};

      return this.setAllSettings(settings);
    }
    else {
      return this.meService.deleteSettings(app);
    }
  }

  private checkStorage(): boolean {
    if (!this.hasStorage) {
      console.log("no local storage");
      return false;
    }
    return true;
  }

  private getAllLocalSettings(): any {

    let settings = window.localStorage.getItem("settings");
    if (!settings) {
      return null;
    }
    try {
      return JSON.parse(settings);
    }
    catch (e) {
      console.log(e);
      return null;
    }

  }

  private setAllSettings(settings: any): Observable<any> {
    window.localStorage.setItem("settings", JSON.stringify(settings));
    return of({});
  }

}
