import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject } from 'rxjs';
import { take, map, debounceTime, throttleTime, tap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Project, Size, Point, Rectangle } from 'paper';
import * as paper from 'paper';

import { Editor } from '../objects/editor';
import { MeService }  from '../me.service';
import { SiteService }  from '../site.service';
import { SequenceService }  from '../sequence.service';
import { SequenceEngineService }  from '../sequence-engine.service';
import { IdeaService }  from '../idea.service';
import { IconService }  from '../icon.service';
import { TextChangedService }  from '../text-changed.service';
import { Tester } from '../objects/tester';
import { StreamService }  from '../stream.service';
import { SettingsService }  from '../settings.service';
import { Site }  from '../site';
import { SocketService }  from '../socket.service';
import { SearchQueryService }  from '../search-query.service';
import { NewStreamService }  from '../new-stream.service';
import { ShowIdeaService }  from '../show-idea.service';
import { Me }  from '../me';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit, AfterViewInit {

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('textInput') textInput: ElementRef<HTMLInputElement>;
  @ViewChild('convo') convo: ElementRef<HTMLIFrameElement>;

  editor: Editor;
  canvasCallback: any;
  tester: Tester;

  embed = false;
  map = false;
  canvasTop = "64px";
  timelineWidth = "200px";
  timelineHeight = "200px";
  fullHeight = "500px";
  showIframeHider = false;
  splitPos = 100;
//  splitPos = 40;
//  ideaWidth = "100%";
  splitDirection = "horizontal";
//  splitDirection = "vertical";
  closeTop = "58px";

  private me: Me;
  private sockets = true;
  private onSocket = new EventEmitter<any>();
  private onUpdate = new EventEmitter<any>();
  private onData = new EventEmitter<any>();

  private timeout = null;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private breakpointObserver: BreakpointObserver,
    private meService: MeService,
    private siteService: SiteService,
    private sequenceService: SequenceService,
    private sequenceEngineService: SequenceEngineService,
    private ideaService: IdeaService,
    private iconService: IconService,
    private streamService: StreamService,
    private settingsService: SettingsService,
    private socketService: SocketService,
    private textChangedService: TextChangedService,
    private searchQueryService: SearchQueryService,
    private newStreamService: NewStreamService,
    private showIdeaService: ShowIdeaService,
    private sanitizer: DomSanitizer,
  ) {
    route.queryParams.subscribe(params => {
      this.sockets = params['sockets'] != "false";
      let extid = params['extsocket'];
      if (extid) {
        this.socketService.setExtID(extid);
      }
      this.embed = params['embed'] == "true";
      this.map = params['map'] == "true";
    });
  }

  ngOnInit() {
    this.canvasTop = this.embed ? "0px" : "64px";
    this.splitDirection = this.isSmall() ? "vertical" : "horizontal";
    this.showIdeaService.showIdeaChanged$.subscribe(idea => {
      if (this.map) {
        if (this.me.name == "public") {
          if (window.parent) {
            window.parent.postMessage(JSON.stringify({ msg: "showidea", idea: idea }), "*");
          }
        }
        else {
          this.meService.showIdea(idea).subscribe(() => {});
        }
      }
      else {
        if (this.me.name == "public") {
          this.convo.nativeElement.contentWindow.postMessage(JSON.stringify({ msg: "showidea", idea: idea }), "*");
        }
        else {
          this.meService.showIdea(idea).subscribe(() => {});
        }
        if (this.splitPos == 100) {
          this.splitPos = this.isSmall() ? 50 : 75;
          this.onResize(null);
        }
      }
    });
  }

  private isSmall(): boolean {
    return this.breakpointObserver.isMatched("(max-width: 960px)");
  }

  ngAfterViewInit(): void {

    this.canvasCallback = (sargs) => {
      return this.tester.test(sargs.split("$"));
    };

    let width = window.innerWidth;
    let height = window.innerHeight - (this.embed ? 0 : 64);

    // set the width and height
    this.canvas.nativeElement.width = width - 4;
    this.canvas.nativeElement.height = height - 4;

    window['paper'] = paper;
    new Project('canvas');
    let url = new URL(window.location.href);
    this.meService.getMe().subscribe(me => {
      this.me = me;
      this.siteService.getSite(url.hostname).subscribe(site => {
        this.editor = new Editor(this.dialog, this.clipboard, this.breakpointObserver,
              this.ideaService, this.iconService, this.streamService,
              this.sequenceService, this.sequenceEngineService,
              this.settingsService, this.socketService,
              this.textChangedService, this.searchQueryService, this.showIdeaService, this.meService,
              this.textInput.nativeElement, me, site, this.sockets, this.map);
        this.tester = new Tester(this.editor);
        this.editor.load(new Size(width, height));
      });
      if (this.sockets) {
        this.onSocket.subscribe(s => {
//          this.status = s;
        });
        if (me) {
          this.socketService.start(me, console, this.onSocket);
        }
        else {
          this.socketService.startDown(console, this.onSocket);
        }
        this.onUpdate.subscribe(data => {
//           console.log("onUpdate", data);
          if (data.type == "stream") {
            if (data.newstream) {
              this.newStreamService.newStream(data.newstream);
            }
            else if (data.idea) {
              this.ideaService.getIdea(data.idea).subscribe(idea => {
                if (idea.sequence && idea.date) {
                  this.editor.addIdea(idea);
                }
              });
            }
          }
        });
        this.onData.subscribe(data => {
//           console.log("onData", data);
          if (data.data.filter(e => e.var == "date").length > 0) {
            this.ideaService.getIdea(data.idea).subscribe(idea => {
              if (idea.sequence && idea.date) {
                this.editor.addIdea(idea);
              }
            });
          }
        });
        this.socketService.registerUpdate(this.onUpdate);
        this.socketService.registerData(this.onData);
        // open docs and set streams are done in the editor.
      }
    });

    this.onResize(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    let width = window.innerWidth - 4;
    let height = window.innerHeight - 4 - (this.embed ? 0 : 64);

    this.fullHeight = height + "px";

    var timelineWidth;
    var timelineHeight;
    if (this.splitDirection == "vertical") {
      timelineHeight = (height  * (this.splitPos / 100)) - 16;
      timelineWidth = width;
      this.closeTop = (timelineHeight + 78) + "px";
    }
    else {
      timelineHeight = height;
      timelineWidth = (width  * (this.splitPos / 100));
      this.closeTop = "58px";
    }

    this.timelineHeight = timelineHeight + "px";
    this.timelineWidth = timelineWidth + "px";

    let v = paper.view as any;
    v.setViewSize(new paper.Size(timelineWidth, timelineHeight));

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.timeout = setTimeout(() => {
      if (this.editor) {
        this.editor.resize(new Size(timelineWidth, timelineHeight));
      }
    }, 20);

  }

  resizeSplit(event): void {
    this.splitPos = event.sizes[0];
    this.onResize(null);
    this.showIframeHider = false;
  }

  canClose(): boolean {
    return this.splitPos < 100;
  }

  close(): void {
    this.splitPos = 100;
    this.onResize(null);
  }

}
