
export interface Targetable {

	target();
  showTarget();
  hideTarget();

}

export function instanceOfTargetable(object: any): object is Targetable {
  return 'target' in object;
}
