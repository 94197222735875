
import { Canvas }  from '../canvas';

export interface Expandable {

  expand(canvas: Canvas);
  contract(canvas: Canvas);

}

export function instanceOfExpandable(object: any): object is Expandable {
  return 'expand' in object;
}
