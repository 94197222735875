
import { Point, Size } from 'paper';

import { Button }  from './button';
import { Canvas }  from '../canvas';

import { Resettable, instanceOfResettable }  from '../interface/resettable';

export class ResetButton extends Button {

  constructor() {

    super();

  }

  public typeName(): string {
    return "ResetButton";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    super.rebuild(topLeft, size);
    this.setContent("Reset");
  }

  public run(canvas: Canvas) {
	  if (instanceOfResettable(canvas)) {
	    (canvas as Resettable).reset(canvas);
	  }
  }

}
