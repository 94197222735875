import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BackendService } from './backend.service';
import { Sequence } from './sequence';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SequenceService extends BackendService {

  private sequencesUrl = '/rest/1.0/sequences';

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
  ) {
    super(dialog, socketService, upService, http)
  }

  getSequenceNoSecurityError(id: string): Observable<Sequence> {
    var url = `${this.sequencesUrl}/${id}?v=resolve`;
    return this.http.get<Sequence>(url).pipe(
      catchError(this.passError<Sequence>(`getSequenceNoSecurityError id=${id}`))
    );
  }

}
