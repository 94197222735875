
import { Project, Size, Point, Rectangle, Item, PointText } from 'paper';
import * as paper from 'paper';

import { Editor } from './editor';
import { Dragable, instanceOfDragable }  from './interface/dragable';
import { Targetable, instanceOfTargetable }  from './interface/targetable';
import { DblClickable, instanceOfDblClickable }  from './interface/dblclickable';
import { Clickable, instanceOfClickable }  from './interface/clickable';
import { Closable, instanceOfClosable }  from './interface/closable';
import { Selectable, instanceOfSelectable }  from './interface/selectable';
import { Expandable, instanceOfExpandable }  from './interface/expandable';

export class Tester {

  private editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }

  public test(args: string[]): string {
    if (args[0] == "matchCount") {
      return this.matchCount(args[1], parseInt(args[2]));
    }
    else if (args[0] == "dragOver") {
      return this.dragOver(args[1], args[2], args[3], args[4]);
    }
    else if (args[0] == "dblClickNth") {
      return this.dblClickNth(args[1], parseInt(args[2]));
    }
    else if (args[0] == "setTextTo") {
      return this.setTextTo(args[1], args[2]);
    }
    else if (args[0] == "click") {
      return this.click(args[1], args[2]);
    }
    else if (args[0] == "dblClick") {
      return this.dblClick(args[1], args[2]);
    }
    else if (args[0] == "select") {
      return this.select(args[1], args[2]);
    }
    else if (args[0] == "close") {
      return this.close(args[1]);
    }
    else if (args[0] == "expand") {
      return this.expand(args[1]);
    }
    else if (args[0] == "stringExists") {
      return this.stringExists(args[1]);
    }

    return "bad function";
  }

  private findWithTypeName(typeName: string, testName: string): paper.Item[] {
    if (testName == "any") {
      return paper.project.getItems({ match: (obj) => { return typeof(obj.typeName) == "function" && obj.typeName() == typeName; } });
    }
    else {
      return paper.project.getItems({ match: (obj) => {
        return typeof(obj.typeName) == "function" && obj.typeName() == typeName && typeof(obj.testName) == "function" && obj.testName() == testName;
      } });
    }
  }

  private matchCount(typeName: string, count: number): string {
    let objs = this.findWithTypeName(typeName, "any");
    if (objs.length == count) {
      return "match";
    }
    return "no match (actually " + length + ")";
  }

  private dragOver(typeName: string, testName: string, overTypeName: string, overTestName: string): string {
    let objs = this.findWithTypeName(typeName, testName);
    if (objs.length > 0) {
      let item = objs[0];
      if (instanceOfDragable(item)) {
        let dragable = (item as any) as Dragable;
        if (overTypeName == "nil") {
          dragable.draggedOver(null);
          dragable.endDrag(this.editor, item.position, null);
          return "match";
        }
        let oobjs = this.findWithTypeName(overTypeName, overTestName);
        if (oobjs.length > 0) {
          let oitem = oobjs[0];
          if (instanceOfTargetable(oitem)) {
            dragable.draggedOver((oitem as any) as Targetable);
            dragable.endDrag(this.editor, item.position, (oitem as any) as Targetable);
            return "match";
          }
          return "match but not over targetable";
        }
        return "no match for over: (" + overTypeName + ", " + overTestName + ")";
      }
      return "match but not dragable";
    }
    return "no match";
  }

  private dblClickNth(typeName: string, n: number): string {
    let objs = this.findWithTypeName(typeName, "any");
    if (objs.length > (n - 1)) {
      let item = objs[n - 1];
      if (instanceOfDblClickable(item)) {
        let dclick = (item as any) as DblClickable;
        dclick.dblClick(this.editor);
        return "match";
      }
      return "match but not double clickable";
    }
    return "no match";
  }

  private setTextTo(field: string, text: string): string {
    this.editor.testEdit(field, text);
    return "match";
  }

  private click(typeName: string, testName: string): string {
    let objs = this.findWithTypeName(typeName, testName);
    if (objs.length > 0) {
      let item = objs[0];
      if (instanceOfClickable(item)) {
        let click = (item as any) as Clickable;
        click.click(this.editor);
        return "match";
      }
      return "match but not clickable";
    }
    return "no match";
  }

  private dblClick(typeName: string, testName: string): string {
    let objs = this.findWithTypeName(typeName, testName);
    if (objs.length > 0) {
      let item = objs[0];
      if (instanceOfDblClickable(item)) {
        let dclick = (item as any) as DblClickable;
        dclick.dblClick(this.editor);
        return "match";
      }
      return "match but not dbl clickable";
    }
    return "no match";
  }

  private select(typeName: string, testName: string): string {
    let objs = this.findWithTypeName(typeName, testName);
    if (objs.length > 0) {
      let item = objs[0];
      if (instanceOfSelectable(item)) {
        let sel = (item as any) as Selectable;
        sel.select(this.editor);
        this.editor.setSelection(sel);
        return "match";
      }
      return "match but not selectable";
    }
    return "no match";
  }

  private close(typeName: string): string {
    let objs = this.findWithTypeName(typeName, "any");
    if (objs.length > 0) {
      let item = objs[0];
      if (instanceOfClosable(item)) {
        let close = (item as any) as Closable;
        close.close(this.editor);
        return "match";
      }
      return "match but not clickable";
    }
    return "no match";
  }

  private stringExists(s: string): string {
    let objs = paper.project.getItems({ match: (obj) => {
      if (obj instanceof PointText) {
        return (obj as paper.PointText).content == s;
      }
      return false;
    } });
    if (objs.length > 0) {
      return "match";
    }
    return "no match";
  }

  private expand(typeName: string): string {
    let objs = this.findWithTypeName(typeName, "any");
    if (objs.length > 0) {
      let item = objs[0];
      if (instanceOfExpandable(item)) {
        let expand = (item as any) as Expandable;
        expand.expand(this.editor);
        return "match";
      }
      return "match but not clickable";
    }
    return "no match";
  }

}
