import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BackendService } from './backend.service';
import { Site } from './site';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';

@Injectable()
export class SiteService extends BackendService {

  private sitesUrl = '/rest/1.0/sites';

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
  ) {
    super(dialog, socketService, upService, http)
  }

  getSite(name: string): Observable<Site> {
    return this.get<Site>(`${this.sitesUrl}/${name}`, "getSite");
  }

}
