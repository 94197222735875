
import { Canvas }  from '../canvas';
import { Box }  from '../box';

export interface Runable {

  run(canvas: Canvas, box: Box);

}

export function instanceOfRunable(object: any): object is Runable {
  return 'run' in object;
}
