
import { project, Tool, Point, Color, Size } from 'paper';

import * as paper from 'paper';
import * as Hammer from 'hammerjs';

import { Box } from './box';

import { Selectable, instanceOfSelectable }  from './interface/selectable';
import { Clickable, instanceOfClickable }  from './interface/clickable';
import { Dragable, instanceOfDragable }  from './interface/dragable';
import { Targetable, instanceOfTargetable }  from './interface/targetable';
import { DblClickable, instanceOfDblClickable }  from './interface/dblclickable';
import { Overable, instanceOfOverable }  from './interface/overable';

export class Canvas {

  private hitOptions = { stroke: true, fill: true, tolerance: 4 };
  private dragStartPoint: paper.Point;
  private dragObj: Dragable = null;
  private targetObj: Targetable = null;
  private selected: Selectable = null;
  private dblClicked: Box = null;
  private dblClickedCanvas = false;
  private clicked: Clickable = null;
  private lastTime: number;
  private panning = false;
  private zooming = false;
  private pinching = false;
  private lastPanZoomPoint: paper.Point;
  private firstPanZoomPoint: paper.Point;
	private _shiftDown = false;

  constructor() {
    let tool = new Tool();
    tool.onMouseDown = this._onMouseDown;
    tool.onMouseDrag = this._onMouseDrag;
    tool.onMouseMove = this._onMouseMove;
    tool.onMouseUp = this._onMouseUp;
    tool.onKeyDown = this._onKeyDown;
    tool.onKeyUp = this._onKeyUp;

    const hammer = new Hammer(paper.view.element, {});
    hammer.get('pinch').set({ enable: true });

    hammer.on('pinchstart', e => {
//      console.log("pinchstart", e.center);
      this.pinching = true;
      this.startZooming(e.center);
    });

    hammer.on('pinch', e => {
//      console.log("pinch", e.scale);
      this.zoom(e.scale);
   });

    hammer.on('pinchend', e => {
      this.pinching = false;
      this.endZoom();
    });
  }

  public finishEdit() {
  }
  public setSelection(selectable: Selectable) {
    this.selected = selectable;
  }
  public bringAllToFront() {
  }

  public getSelection(): Selectable {
    return this.selected;
  }
  public startPanning() {
  }
  public pan(offset: paper.Size) {
  }
  public endPan() {
  }
  public startZooming(position: paper.Point) {
  }
  public zoom(scale: number) {
  }
  public endZoom() {
  }

  public altDown(state: boolean) {
  }
  public shiftDown(state: boolean) {
  	this._shiftDown = state;
  }
  public cmdDown(state: boolean) {
  }
	public isShiftDown(): boolean {
		return this._shiftDown;
	}

  public dblClick(point: paper.Point) {
  }

  private getHitBox(point: paper.Point): Box {

    var hitResult = project.hitTest(point, this.hitOptions);
    if (hitResult) {
      return this.getBox(hitResult.item, false);
    }
    return null;

  }

  private getBox(item: any, drag: boolean): Box {
    var b = item;
    while (b && b.parent && !(b instanceof Box)) {
      b = b.parent;
    }
    if (b instanceof Box) {
      var box = b as Box;
      if (box.wasHit(item, drag)) {
        return box;
      }
    }
    return null;
  }

  private _onMouseDown = (event) => {

    if (this.pinching) {
      return;
    }

    this.finishEdit();

    this.dragObj = null;
    var doPan = false;
    var box = this.getHitBox(event.point);
    if (box) {
      var deselect = false;
      if (instanceOfDblClickable(box)) {
        if (this.dblClicked == box) {
          var now = new Date().getTime();
          if (now - this.lastTime < 500) {
            (box as DblClickable).dblClick(this);
            this.dblClicked = null;
          }
          this.lastTime = now;
        }
        else {
          this.lastTime = new Date().getTime();
          this.dblClicked = box;
          this.dblClickedCanvas = false;
        }
      }
      if (instanceOfSelectable(box)) {
        let selectable = (box as any) as Selectable;
        if (selectable && selectable.canSelect()) {
          if (this.selected) {
            this.selected.deselect(this);
          }
          selectable.select(this);
          this.setSelection(selectable);
        }
        else {
          deselect = true;
        }
      }

      if (instanceOfDragable(box)) {
        box.bringForward();
        this.dragObj = (box as any) as Dragable;
        this.dragObj.draggingStarted(this);
        this.dragStartPoint = box.position;
      }
      else {
        // we might pan.
        doPan = true;
      }

      if (instanceOfClickable(box)) {
        this.clicked = (box as Clickable);
        this.clicked.drawOver();
        // no we won't pan.
        doPan = false;
      }

      if (deselect && this.selected) {
        // we definitely won't pan
        doPan = false;
        this.selected.deselect(this);
        this.clicked = null;
        this.dblClicked = null;
        this.dblClickedCanvas = false;
        this.setSelection(null);
      }
    }
    else {
      if (this.dblClickedCanvas) {
        var now = new Date().getTime();
        if (now - this.lastTime < 500) {
          this.dblClick(event.point);
          this.dblClickedCanvas = false;
        }
        this.lastTime = now;
      }
      else {
        this.lastTime = new Date().getTime();
        this.dblClicked = null;
        this.dblClickedCanvas = true;
      }
      doPan = true;
    }

    if (doPan || (!this.clicked && !this.dblClicked && !this.dblClickedCanvas && !this.dragObj)) {
      if (this.selected) {
        this.selected.deselect(this);
        this.setSelection(null);
      }
      // always an even after selection.
      this.lastPanZoomPoint = event.point;
      this.firstPanZoomPoint = event.point;
      if (this.isShiftDown()) {
        this.zooming = true;
        this.startZooming(event.point);
      }
      else {
        this.panning = true;
        this.startPanning();
      }
    }
  }

  private _onMouseDrag = (event) => {

    if (this.pinching) {
      return;
    }

    if (this.dragObj) {
      if (this.clicked) {
        this.clicked.drawOff();
        this.clicked = null;
      }
      this.dragObj.dragBy(this, new Size(event.delta));
      var hitResult = project.hitTestAll(event.point, this.hitOptions);
      for (var i=0; i<hitResult.length; i++) {
        let box = this.getBox(hitResult[i].item, true);
        if (box && instanceOfDragable(box)) {
          let dragable = (box as any) as Dragable;
          if (dragable != this.dragObj) {
            dragable.dragOver(this.dragObj);
            if (instanceOfTargetable(box)) {
              let targetable = (box as any) as Targetable;
              if (targetable != this.targetObj) {
                if (this.targetObj) {
                  this.targetObj.hideTarget();
                  this.targetObj = null;
                }
                if (this.dragObj.draggedOver(targetable)) {
                  this.targetObj = targetable;
                  this.targetObj.showTarget();
                }
              }
            }
            return;
          }
        }
        else if (box && instanceOfTargetable(box)) {
          let targetable = (box as any) as Targetable;
          if (targetable != ((this.dragObj as any) as Targetable)) {
            if (targetable != this.targetObj) {
                if (this.targetObj) {
                  this.targetObj.hideTarget();
                  this.targetObj = null;
                }
                if (this.dragObj.draggedOver(targetable)) {
                  this.targetObj = targetable;
                  this.targetObj.showTarget();
                }
            }
            return;
          }
        }
        else {
          if (this.targetObj) {
            this.targetObj.hideTarget();
            this.targetObj = null;
          }
        }
      }
      if (this.targetObj) {
        this.targetObj.hideTarget();
        this.targetObj = null;
      }
      this.dragObj.draggedOver(null);
    }
    else {
      if (this.panning) {
        this.pan(new paper.Size(event.point.x - this.lastPanZoomPoint.x, event.point.y - this.lastPanZoomPoint.y));
        this.lastPanZoomPoint = event.point;
      }
      else if (this.zooming) {
        this.lastPanZoomPoint = event.point;
        let dist = this.lastPanZoomPoint.x - this.firstPanZoomPoint.x;
        let scale = 1 + (dist/100);
        this.zoom(scale);
      }
    }
  }

  private _onMouseMove = (event) => {

    if (this.pinching) {
      return;
    }

    var box = this.getHitBox(event.point);
    if (instanceOfOverable(box)) {
      ((box as any) as Overable).over(this, event.point);
    }

  }

  private _onMouseUp = (event) => {

    if (this.pinching) {
      return;
    }

    if (this.panning) {
      this.endPan();
      this.panning = false;
      return;
    }

    if (this.zooming) {
      this.endZoom();
      this.zooming = false;
      return;
    }

    var box = this.getHitBox(event.point);
    if (box) {
      if (this.clicked) {
        if (instanceOfClickable(box)) {
          let clickable = (box as any) as Clickable;
          if (clickable == box) {
            clickable.click(this);
          }
        }
      }
    }

    if (this.clicked) {
      this.clicked.drawOff();
      this.clicked = null;
    }

    if (this.dragObj) {
      this.dragObj.endDrag(this, this.dragStartPoint, this.targetObj);
      if (this.targetObj) {
        this.targetObj.hideTarget();
      }
      this.dragObj = null;
      this.targetObj = null;
    }
  }

  private _onKeyDown = (event) => {
    if (event.key == "shift") {
      this.shiftDown(true);
    }
    else if (event.key == "alt") {
      this.altDown(true);
    }
    else if (event.key == "meta") {
      this.cmdDown(true);
    }
  }

  private _onKeyUp = (event) => {
    if (event.key == "shift") {
      this.shiftDown(false);
    }
    else if (event.key == "alt") {
      this.altDown(false);
    }
    else if (event.key == "meta") {
      this.cmdDown(false);
    }
  }

}
