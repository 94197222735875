
import { Point, Size, Rectangle, Path, Color } from 'paper';

import * as _ from 'lodash';

import { ClosablePalette }  from './closable-palette';
import { Text }  from '../text';

export class LegendPalette extends ClosablePalette {

  static WIDTH: number = 180;

  private legends = null;

  static allWhiteTextColors = [
    "darkGrey",
    "grey",
    "red",
    "green",
    "blue",
    "magenta",
    "purple",
    "brown",
    "black",
  ];

  constructor(legends) {

    super("Legend", false);

    this.legends = legends;

  }

  calcHeight() {
    var height = 30;
    this.legends.forEach(e => {
      height += 20 + (e.data.length * 22);
    });
    height += 8;
    return height;
  }

  public typeName(): string {
    return "LegendPalette";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    super.rebuild(topLeft, new Size(LegendPalette.WIDTH, this.calcHeight()));
  }

  public build(): paper.Item[] {

		var objs = super.build();

    if (this.legends) {
      let size = this.size();
      var top = 40;
      this.legends.forEach(l => {
        let text = new Text(new Point(size.width / 2, top));
        text.justification = "center";
        text.content = l.name;
        objs.push(text);
        top += 20;
        if (l.data) {
          l.data.forEach(d => {
            let rect = new Path.Rectangle(new Rectangle(new Point(12, top-14), new Size(size.width-24, 20)));
            rect.closed = true;
            rect.fillColor = new Color(d.value);
            objs.push(rect);
            let text = new Text(new Point(20, top));
            text.content = d.text;
            if (LegendPalette.allWhiteTextColors.indexOf(d.value.toLowerCase()) >= 0) {
              text.fillColor = new Color("white");
            }
            objs.push(text);
            top += 22;
          });
        }
      });
    }

    return objs;

  }

}
