
import { Size, Point, Item, Rectangle, Path, Color } from 'paper';

import * as moment from 'moment';

import { Me }  from '../me';
import { Idea }  from '../idea';

import { Canvas }  from './canvas';
import { Editor }  from './editor';
import { Box }  from './box';
import { Text }  from './text';
import { LinkButton }  from './button/link-button';
import { ObjectColors }  from './object-colors';
import { HelpLayer }  from './help-layer';

import { Linkable }  from './interface/linkable';
import { Selectable }  from './interface/selectable';

export class IdeaPopup extends Box implements Linkable, Selectable {

  private _size: paper.Size;
  private editor: Editor;
  private linkButton: LinkButton;

  idea: Idea;

  static WIDTH: number = 300;

  constructor(editor: Editor, idea: Idea,
  ) {

    super();
    this.editor = editor;
    this.idea = idea;

  }

  public typeName(): string {
    return "IdeaPopup";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {

    this._size = size;
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);

  }

  public size(): paper.Size {
    return this._size;
  }

  private formatTime(duration: number): string {

    let seconds = duration;
    if (seconds > 60) {
      let minutes = seconds/60;
      if (minutes > 60) {
        let hours = minutes/60;
        if (hours > 24) {
          let d = hours/24;
          let days = Math.trunc(d);
          let hrs = (d - days) * 24;
          return days + " days " + (Math.round(hrs * 100) / 100) + " hrs";
        }
        return (Math.round(hours * 100) / 100) + " hours";
      }
      return (Math.round(minutes * 100) / 100) + " minutes";
    }
    return (Math.round(seconds * 100) / 100) + " seconds";
  }

  private _build(): paper.Item[] {

 		var objs = [];
    objs.push(this.blank());
//    objs.push(this.debug());

    let size = this.size();
    let hasDuration = this.idea.views && this.idea.views.timeline && this.idea.views.timeline.duration;
    let textcolor = new Color(this.idea.views && this.idea.views.all && this.idea.views.all.textColor ? this.idea.views.all.textColor : "black");

    let date = new Text(new Point(8, 16));
    date.fillColor = textcolor;
    date.content = moment(this.idea.date).format(this.idea.dateOnly ? "ll" : "lll");


    let text = new Text(new Point(8, hasDuration ? 50 : 36));
    text.fillColor = textcolor;
    text.fontWeight = "bold";
    text.wrapText(this.idea.text, size.width);

    let rect = new Rectangle(new Point(0, 0), new Size(size.width, text.bounds.height + (hasDuration ? 46 : 30)));
    var border = new Path.Rectangle(rect, new Size(3, 3));
    border.closed = true;
    border.fillColor = this.idea.views && this.idea.views.all && this.idea.views.all.bgColor ? ObjectColors.parseColor(this.idea.views.all.bgColor, false) : new Color("white");
    border.strokeColor = this.idea.views && this.idea.views.all && this.idea.views.all.color ? ObjectColors.parseColor(this.idea.views.all.color, false) : new Color("black");
    border.strokeWidth = 1;

    objs.push(border);
    objs.push(date);
    objs.push(text);

    if (hasDuration) {
      let duration = new Text(new Point(8, 32));
      duration.fillColor = textcolor;
      duration.content = "Duration: " + this.formatTime(this.idea.views.timeline.duration);
      objs.push(duration);
    }

    let button = new LinkButton();
    button.rebuild(new Point(size.width - 18, 2), new Size(0, 0));
    objs.push(button);

    return objs;

  }

  // Selectable
  canSelect(): boolean {
    return true;
  }

  public select(canvas: Canvas) {
  }

  public deselect(canvas: Canvas) {
    if (canvas instanceof Editor) {
      (canvas as Editor).closePopup();
    }
  }

  // Linkable
  linkTo() {
    this.editor.showIdea(this.idea._id);
  }

}
