
import { Size, Point, Item, Rectangle, Path, Color, Group, Raster } from 'paper';

import { Box }  from './box';
import { Stream }  from '../stream';
import { Idea }  from '../idea';

import { Indexable }  from './interface/indexable';

export class StreamObjectBase extends Box implements Indexable {

  stream: Stream;
  streamIndex: number;

  static TITLE_WIDTH: number = 200;
  static COLLAPSED_SIZE: number = 60;
  static HEIGHT_EXTRA: number = 20;
  static STREAMBG_HEIGHT: number = 56;

  addIdeas(ideas: Idea[]) {
  }
  addOneIdea(idea: Idea): boolean {
    return false;
  }
  setLayout() {
  }
  removeIdeas() {
  }
  bringAllToFront() {
  }
  pan(offset: paper.Size) {
  }
  toggleExpand() {
  }
  doExpand() {
  }
  doContract() {
  }

  // Indexable
  getIndex(): number {
    return -1;
  }
  setIndex(index: number) {
  }

}
