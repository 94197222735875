
import { Point, Size } from 'paper';

import { IconButton }  from './icon-button';
import { Canvas }  from '../canvas';
import { Editor }  from '../editor';
import { Icons }  from '../icons';

export class ToolbarButton extends IconButton {

  constructor() {

    super(32, "#dadada", "black");

  }

  public typeName(): string {
    return "ToolbarButton";
  }

  public setContent() {
    this.setIcon(Icons.TOOLBAR);
  }

	public click(canvas: Canvas) {
	  (canvas as Editor).showToolbar();
  }

}
