import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BackendService } from './backend.service';
import { Site } from './site';
import { SocketService }  from './socket.service';
import { UpService }  from './up.service';

@Injectable({
  providedIn: 'root'
})
export class UIBuildService extends BackendService {

  private uibuildUrl = '/rest/1.0/uibuild';

  constructor(
    dialog: MatDialog,
    socketService: SocketService,
    upService: UpService,
    http: HttpClient,
  ) {
    super(dialog, socketService, upService, http)
  }

  getUIBuild(app: string): Observable<string> {
    return this.get<string>(`${this.uibuildUrl}/${app}`, "getUIBuild");
  }

}
