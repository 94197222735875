
import { Path, Point, Rectangle, Size, Color, Group, Item } from 'paper';

export class Box extends Group {

  constructor() {
    super();
  }

  public release() {
    this.children.forEach(c => {
      if (c instanceof Box) {
        (c as Box).release();
      }
    });
  }

  public animate(time: number) {
  }

  public isMainParent(): boolean {
    return false;
  }

  private findMainParent(): Box {
    let p = this as paper.Item;
    while (p.parent) {
      if (p.parent instanceof Box) {
        let b = p.parent as Box;
        if (b.isMainParent()) {
          return b;
        }
      }
      p = p.parent;
    }
    return null;
  }

  public bringForward() {
    let p = this.findMainParent();
    if (p) {
      p.bringToFront();
    }
    this.bringToFront();
  }

  public typeName(): string {
    return "Box";
  }

  public wasHit(item: paper.Item, drag: boolean): boolean {
    return true;
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    // always something like.
    // this._size = size;
    // this.removeChildren();
    // this.addChildren(this._build());
    // this.moveTo(topLeft);
    // this.layout();
  }

  public layout() {
    // make sure your all layed out in the Y direction.
    // This is needed for expanding and contracting.
  }

  public doLayout() {
    this.layout();
    // allow parents to layout
  }

  public size(): paper.Size {
    return new Size(0, 0);
  }

  public moveItemTo(item: paper.Item, topLeft: paper.Point) {
    let size = item.bounds.size.divide(2);
	  item.position = topLeft.add(new Point(size.width, size.height));
  }

  public resizeItemTo(item: paper.Item, size: paper.Size) {

    var prevPos = new Point(item.bounds);
    item.scale(size.width/item.bounds.width, size.height/item.bounds.height);
    item.position = prevPos.add(new Point(item.bounds.width/2, item.bounds.height/2));

  }

  public moveTo(topLeft: paper.Point) {
    let size = this.size().divide(2);
	  this.position = topLeft.add(new Point(size.width, size.height));
  }

  public filled(fillColor: string = "white"): paper.Path.Rectangle {
    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect);
    r.closed = true;
    r.fillColor = new Color(fillColor);
    return r;
  }

  public border(fillColor: string = "white"): paper.Path.Rectangle {
    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect);
    r.closed = true;
    r.fillColor = new Color(fillColor);
    r.strokeColor = new Color('black');
    r.strokeWidth = 1;
    return r;
  }

  public empty(): paper.Path.Rectangle {
    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect);
    r.closed = true;
    r.fillColor = new Color('white');
    return r;
  }

  public blank(): paper.Path.Rectangle {
    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect);
    r.closed = true;
    return r;
  }

  public debug(): paper.Path.Rectangle {
    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect);
    r.closed = true;
    r.fillColor = new Color('red');
    r.strokeColor = new Color('blue');
    r.strokeWidth = 1;
    return r;
  }

  public inset(): paper.Path.Rectangle {

    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect.expand(-4));
    r.closed = true;
    r.fillColor = new Color('white');
    r.strokeColor = new Color('darkgrey');
    r.strokeWidth = 1;
    return r;

  }

  public clippedBorder(): paper.Path.Rectangle {

    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect.expand(-1));
    r.closed = true;
    r.fillColor = new Color('white');
    r.strokeColor = new Color('black');
    r.strokeWidth = 1;
    return r;

  }

  public buttonBorder(color: string, width: number): paper.Path.Rectangle {
    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect, new Size(4, 4));
    r.closed = true;
    r.fillColor = new Color(color);
    r.strokeColor = new Color('black');
    r.strokeWidth = width;
    return r;
  }

  public shadow(): paper.Path.Rectangle {

    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect, new Size(5, 5));
    r.closed = true;
    r.fillColor = new Color('white');
    r.shadowColor = new Color('silver');
    r.shadowBlur = 10;
    r.shadowOffset = new Point(5, 5);

    return r;
  }

  public paletteBorder(): paper.Path.Rectangle {

    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect.expand(-1), new Size(5, 5));
    r.closed = true;
    r.fillColor = new Color('white');
    r.strokeColor = new Color('black');
    r.strokeWidth = 1;
    return r;

  }

  public insetRect(): paper.Path.Rectangle {

    let size = this.size();
    let rect = new Rectangle(new Point(0, 0), size);
    let r = new Path.Rectangle(rect.expand(-4), new Size(5, 5));
    r.closed = true;
    r.fillColor = new Color('white');
    r.strokeColor = new Color('white');
    r.strokeWidth = 1;
    return r;

  }

  public dump() {
  }
}
