import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

import * as moment from 'moment';

import { SearchQuery }  from './search-query';

@Injectable({
  providedIn: 'root'
})
export class SearchQueryService {

  private queryChangedSource = new Subject<SearchQuery>();
  queryChanged$ = this.queryChangedSource.asObservable();
  private opened = false;

  constructor() { }

  changed(value: string) {
    if (!this.opened) {
      this.open();
    }
    var q = new SearchQuery();
    q.text = value;
    this.queryChangedSource.next(q);
  }

  advanced(query: SearchQuery) {
    if (!this.opened) {
      this.open();
    }
    this.queryChangedSource.next(query);
  }

  open() {
    this.opened = true;
    var q = new SearchQuery();
    q.opened = true;
    this.queryChangedSource.next(q);
  }

  latest() {
    var q = new SearchQuery();
    q.opened = true;
    q.latest = true;
    this.queryChangedSource.next(q);
  }

  now() {
    var q = new SearchQuery();
    q.opened = true;
    q.now = true;
    this.queryChangedSource.next(q);
  }

  close() {
    this.opened = false;
    var q = new SearchQuery();
    q.opened = false;
    this.queryChangedSource.next(q);
  }

}
