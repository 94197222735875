
import { Canvas }  from '../canvas';

export interface Resettable {

  reset(canvas: Canvas);

}

export function instanceOfResettable(object: any): object is Resettable {
  return 'reset' in object;
}
