<form [formGroup]="form" (ngSubmit)="submit(form)" class="modal-dialog">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <mat-dialog-content>
    <p class="mat-typography">{{data.description}}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit">Ok</button>
  </mat-dialog-actions>
</form>
