
import { Size, Point, Item, Rectangle, Path, Color } from 'paper';

import { Box }  from './box';

export class InsertPoint extends Box {

  private _size: paper.Size;

  constructor() {

    super();

  }

  public typeName(): string {
    return "InsertPoint";
  }

  public rebuild(topLeft: paper.Point, size: paper.Size) {
    this._size = new Size(size.width, 2);
    this.removeChildren();
    this.addChildren(this._build());
    this.moveTo(topLeft);
  }

  public size(): paper.Size {
    return this._size;
  }

  private _build(): paper.Item[] {

 		var objs = [];

    let size = this.size();
    let r = new Path.Rectangle(new Rectangle(new Point(0, 0), size));
    r.closed = true;
    r.fillColor = new Color('grey');
 		objs.push(r);

    return objs;

  }

}
