
export enum UIBits {
  none = 0,
  showMyQ = 1 << 0,
  hideStreamsWhenPublic = 1 << 1,
  canUseSketch = 1 << 2,
  sketchIsAdvanced = 1 << 3,
  showName = 1 << 4,
  aggressiveFilter = 1 << 5,
  showArchiveStream = 1 << 6,
  showAdmin = 1 << 7,
  showNewStream = 1 << 8,
  showShareStream = 1 << 9,
  notUsed1 = 1 << 10,
  showIdeaMenuButton = 1 << 11,
  showDeleteIdea = 1 << 12,
  showArchiveIdea = 1 << 13,
  showMoveToStreamOnIdea = 1 << 14,
  notUsed2 = 1 << 15,
  notUsed3 = 1 << 16,
  showInfoOnIdea = 1 << 17,
  showActionIdea = 1 << 18,
  buttonView = 1 << 19,
  disableChat = 1 << 20,
  disableActions = 1 << 21,
  hideAAG = 1 << 22,
  hidePager = 1 << 23
}
